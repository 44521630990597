import React, { useState } from 'react'
import './auth.css'
import logo from '../../assets/Aspom-Logo.png'
import { RiShieldKeyholeLine } from "react-icons/ri";
import { CiLock } from "react-icons/ci";
import { MdOutlineMailOutline } from "react-icons/md";
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom';

const ResetPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const {
        register,
        handleSubmit,
        formState:{errors},
        getValues
    } = useForm()

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const onSubmit = async (data) =>{
        console.log(data)
    }
  return (
    <div className='auth'>
        <div className="auth__container">
            <img src={logo} alt="" className="auth__logo" />
            <div className="auth__content">
                <RiShieldKeyholeLine className='auth__icon'/>
                <h3 className="auth__title">
                    Rset Password
                </h3>
                <span className="auth__subtitle">
                    Enter a new password to reset your password
                </span>
                <form className="auth__form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="auth__list">
                        <div className="auth__items">
                            <label htmlFor="email" className="auth__label">
                                New Password
                            </label>
                            <span className="auth__form-span">
                                <CiLock className='auth__items-icon'/>
                            </span>
                            <input type="password" className="auth__input" name='password'
                            {...register('password',{required: 'This field is required'})}/>
                            {
                                errors.password && <span className="auth__error">
                                    {errors.password.message}
                                </span>
                            }
                        </div>
                        <div className="auth__items">
                            <label htmlFor="email" className="auth__label">
                                Confirm New Password
                            </label>
                            <span className="auth__form-span">
                                <CiLock className='auth__items-icon'/>
                            </span>
                            <input type="password" className="auth__input" name='cpassword'
                            {...register('cpassword',{
                                validate: (value)=>{
                                    const {password} = getValues()
                                    if(value !== password){
                                        return 'Password does not match'
                                    }
                                }
                            })}/>
                            {
                                errors.cpassword && <span className="auth__error">
                                    {errors.cpassword.message}
                                </span>
                            }
                        </div>
                        <div className="auth__items">
                            <Link to={'/login'} className='auth__link'>Back to Login</Link>
                        </div>
                        <div className="auth__items">
                            <button type='submit' 
                            className="auth__btn btn">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default ResetPassword