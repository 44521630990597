import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    appraisal: [],
    userAppraisal: {}
}

const appraisalSlice = createSlice({
    name: 'appraisal',
    initialState,
    reducers: {
        addAppraisal: (state, action)=>{
            state.appraisal = action.payload
        },
        addUserAppraisal: (state, action)=>{
            state.userAppraisal = action.payload
        },
    }
})

export const handleAppraisal = (state)=>{
    return dispatch => {
        dispatch(appraisalSlice.actions.addAppraisal(state))
    }
}

export const handleUserAppraisal = (state)=>{
    return dispatch => {
        dispatch(appraisalSlice.actions.addUserAppraisal(state))
    }
}


export default appraisalSlice.reducer