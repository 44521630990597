import React, { useState } from 'react'
import './auth.css'
import logo from '../../assets/Aspom-Logo.png'
import { RiShieldKeyholeLine } from "react-icons/ri";
import { BiUser } from "react-icons/bi";
import { MdOutlineMailOutline } from "react-icons/md";
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const {
        register,
        handleSubmit,
        formState:{errors},
    } = useForm()

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const onSubmit = async (data) =>{
        console.log(data)
    }
  return (
    <div className='auth'>
        <div className="auth__container">
            <img src={logo} alt="" className="auth__logo" />
            <div className="auth__content">
                <RiShieldKeyholeLine className='auth__icon'/>
                <h3 className="auth__title">
                    forgot Password
                </h3>
                <span className="auth__subtitle">
                    Enter your email a password reset link 
                    will be sent to your email address
                </span>
                <form className="auth__form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="auth__list">
                        <div className="auth__items">
                            <label htmlFor="email" className="auth__label">
                                Email
                            </label>
                            <span className="auth__form-span">
                                <MdOutlineMailOutline className='auth__items-icon'/>
                            </span>
                            <input type="text" className="auth__input" name='email'
                            {...register('email',{required: 'This field is required'})}/>
                            {
                                errors.email && <span className="auth__error">
                                    {errors.email.message}
                                </span>
                            }
                        </div>
                        <div className="auth__items">
                            <Link to={'/login'} className='auth__link'>Back to Login</Link>
                        </div>
                        <div className="auth__items">
                            <button type='submit' 
                            className="auth__btn btn">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default ForgotPassword