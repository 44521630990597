import React, { useState, useEffect } from 'react';
import './locationForm.css'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import L from 'leaflet'; // Import Leaflet
import {useForm} from 'react-hook-form';
import LocationTable from '../locationTable/LocationTable';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { addLocation, getLocations } from '../../api/locationApi'
import Loader from '../loader/Loader';
import {handleAddLocation} from '../../state/locationSlice'
import axios from 'axios'


// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);

// Custom marker icon
const customMarkerIcon = new L.Icon({
    iconUrl: '/logo.png',
    className: 'custom-marker-icon',
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});


const LocationForm = ({user, dispatch}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [markerPosition, setMarkerPosition] = useState();
    const [clickedLocationName, setClickedLocationName] = useState(null);
    const [name, setName] = useState()
    const {
        register, 
        handleSubmit, 
        reset
    } = useForm({
        defaultValue:{
            latitude: markerPosition?.lat,
            longitude: markerPosition?.lon,
            address: markerPosition?.display_name,
            city: markerPosition?.city,
            state: markerPosition?.state,
            country: markerPosition?.country,
            name: '',
            postalCode: markerPosition?.postcode
        }
    })

    const handleMapClick = async (e) => {
        if (!e.latlng) return; // Handle case when latlng is null

        const { lat, lng } = e.latlng;
        setMarkerPosition(null)
                
        // Perform reverse geocoding to get the name of the clicked location
        fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`)
            .then(response => response.json())
            .then(data => {
                if (data && data.display_name) {
                    console.log(data)
                    setMarkerPosition(data)
                } else {
                    setMarkerPosition(null)
                    setClickedLocationName('Unnamed Location');
                }
            })
            .catch(error => {
                console.error('Error fetching location name:', error);
                setMarkerPosition(null)
                setClickedLocationName('Unnamed Location');
            });
    };

    const DrawEvents = () => {
        const map = useMapEvents({
            click: handleMapClick,
        });
        return null;
    };

    const handleFetchLocations = async ()=>{
        try{
            const res = await getLocations(user?.token);
            dispatch(handleAddLocation(res?.data))
        }catch(error){
            console.log(error)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try{
            const data = {
                name: name,
                latitude: markerPosition?.lat,
                longitude: markerPosition?.lon,
                address: markerPosition?.display_name,
                city: markerPosition?.city,
                state: markerPosition?.state,
                country: markerPosition?.country,
                postalCode: markerPosition?.postcode
            }
            const res = await addLocation(user?.token, data)
            if(res?.success){
                MySwal.fire({
                    icon: 'success',
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 4000
                });
                handleFetchLocations()
                setMarkerPosition(null)
                setName(null)
            }else{
                MySwal.fire({
                    icon: 'error',
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 5000
                });
            }
            setIsLoading(false)
        }catch(error){
            setIsLoading(false)
            console.log(error)
            MySwal.fire({
                icon: 'error',
                title: "There's an error processing your request, Try again",
                showConfirmButton: false,
                timer: 5000
            });
        }
    }

    useEffect(()=>{
        handleFetchLocations()
    },[])

    return (
        <div className="location__form">
            <div className="location__form-heading">
                <h3 className="location__form-title">
                    Add New Access Point on the Map
                </h3>
                <p className="location__form-desc">
                    Please click on the map to select the desired 
                    location for accessing the intranet. 
                    Your chosen point will serve as an access point for authorized users.
                </p>
            </div>
            <div className="location__form-content">
                <div className="location__form-map">
                    <MapContainer center={[9.082, 8.6753]} zoom={7} 
                    style={{ height: '400px', width: '100%' }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <DrawEvents />
                        {markerPosition && <Marker position={markerPosition} icon={customMarkerIcon}>
                            <Popup>{clickedLocationName || 'Unnamed Location'}</Popup>
                        </Marker>}
                    </MapContainer>
                </div>
                <form onSubmit={onSubmit}
                className="location__form-form">
                    <h3 className="location__form-title">
                        Chosen Location Details
                    </h3>
                    <div className="location__form-group">
                        <div className="location__form-items">
                            <label htmlFor="name"
                            className="location__form-label">
                                Name
                            </label>
                            <input type="text" id="name" name="name"
                            className="location__form-input"
                            placeholder="Enter a unique name e.g Headquater"
                            value={name} onChange={(e)=>setName(e.target.value)}
                            required
                            />
                        </div>
                    </div>
                    <div className="location__form-group">
                        <div className="location__form-items">
                            <label htmlFor="latitude"
                            className="location__form-label">
                                Latitude
                            </label>
                            <input type="number" id="latitude" name="latitude"
                            className="location__form-input"
                            readOnly 
                            value={markerPosition?.lat}
                            />
                        </div>
                        <div className="location__form-items">
                            <label htmlFor="longitude"
                            className="location__form-label">
                                Longitude
                            </label>
                            <input type="number" id="longitude" name="longitude"
                            className="location__form-input"
                            readOnly value={markerPosition?.lon}
                            />
                        </div>
                    </div>
                    <div className="location__form-items">
                        <label htmlFor="address"
                        className="location__form-label">
                            Address
                        </label>
                        <input type="text" id="address" name="address"
                        className="location__form-input"
                        readOnly value={markerPosition?.display_name}
                        />
                    </div>
                    <div className="location__form-group">
                        <div className="location__form-items">
                            <label htmlFor="city"
                            className="location__form-label">
                                City
                            </label>
                            <input type="text" id="city" name="city"
                            className="location__form-input"
                            readOnly value={markerPosition?.address?.city}
                            />
                        </div>
                        <div className="location__form-items">
                            <label htmlFor="state"
                            className="location__form-label">
                                State
                            </label>
                            <input type="text" id="state" name="state"
                            className="location__form-input"
                            readOnly value={markerPosition?.address?.state}
                            />
                        </div>
                        <div className="location__form-items">
                            <label htmlFor="postalCode"
                            className="location__form-label">
                                Postal Code
                            </label>
                            <input type="number" id="postalCode" name="postalCode"
                            className="location__form-input"
                            readOnly value={markerPosition?.address?.postcode}
                            />
                        </div>
                        <div className="location__form-items">
                            <label htmlFor="country"
                            className="location__form-label">
                                Country
                            </label>
                            <input type="text" id="country" name="country"
                            className="location__form-input"
                            readOnly value={markerPosition?.address?.country}
                            />
                        </div>
                    </div>
                    <div className="location__form-items">
                        {isLoading ? <Loader/> :
                            <button 
                            type="submit"
                            className="location__form-btn btn">
                                Submit
                            </button>
                        }
                    </div>
                </form>
            </div>
            <div className="location__form-table">
                <div>
                    <LocationTable/>
                </div>
            </div>
        </div>
        
    );
}

export default LocationForm