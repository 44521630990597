import axios from 'axios'


// const API_URL = 'http://localhost:5000/api-v1/'
// const API_URL = 'https://backend.aspomportal.com/api-v1/'
const API_URL = 'https://aspom-server.onrender.com/api-v1/'


export const api = axios.create({
    baseURL: API_URL,
    responseType: 'json'
})

export const apiRequest = async ({ method, url, data, token, headers }) => {
    try {
        const response = await api(url,{
            method: method,
            data: data || {},
            headers: {
                "Content-Type" : headers ? headers : "application/json",
                Authorization: token ? `Bearer ${token}` : '',
            }
        })

        return response.data
    } catch (error) {
        console.log(error)
        const err = error?.response?.data
        if(err.message === 'Authorization failed' || err.message === 'Authorization failed!'){
            localStorage.removeItem('Aspom_travels_agency')
            window.location.replace('/login')
        }

        return{success: err.success, message: err.message}
    }
}

export const getUserInfo = async (token, id)=>{
    try {
        const response = await apiRequest({
            url: id ? `/auth/account/${id}` : `/auth/account`,
            token: token,
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const getAllAcounts = async (token)=>{
    try {
        const response = await apiRequest({
            url: '/auth/accounts',
            token: token,
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const getAllUsers = async (token)=>{
    try {
        const response = await apiRequest({
            url: '/auth/users',
            token: token,
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const addEmployee = async (token, data)=>{
    try{
        const response = await apiRequest({
            url: '/auth/add-employee',
            method: 'POST',
            data: data,
            token
        })

        return response
    }catch(error){
        console.log(error)
    }
}

export const addAdmin = async (token, data)=>{
    try{
        const response = await apiRequest({
            url: '/auth/admin',
            method: 'POST',
            data: data,
            token
        })

        return response
    }catch(error){
        console.log(error)
    }
}

export const getAdmin = async (token)=>{
    try{
        const response = await apiRequest({
            url: '/auth/admin',
            token
        })

        return response
    }catch(error){
        console.log(error)
    }
}

export const userAttendance = async (token)=>{
    try {
        const response = await apiRequest({
            url: '/attendance',
            token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const changePassword = async (token, data) => {
    try {
        const response = await apiRequest({
            url: '/auth/change-password',
            method: 'PUT',
            data,
            token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}