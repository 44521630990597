import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    post: []
}

const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        createPost: (state, action) =>{
            state.post = action.payload
        }
    }
})


export const postState = (val)=>{
    return dispatch => dispatch(postSlice.actions.createPost(val))
}

export default postSlice.reducer