import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: JSON.parse(localStorage.getItem('Aspom_travels_agency')) ?? {},
    accessToken: JSON.parse(localStorage.getItem('accessToken')) ?? '',
    users: [],
    staffs: [],
    admins: [],
    notifications: []
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action)=>{
            state.user = action.payload
            localStorage.setItem("Aspom_travels_agency", JSON.stringify(action.payload))
        },
        logout: (state, action)=>{
            state.user = {}
            localStorage.removeItem('user')
        },
        mailToken: (state, action)=>{
            state.accessToken = action.payload
            localStorage.setItem("accessToken", JSON.stringify(action.payload))
        },
        addUsers: (state, action)=>{
            state.users = action.payload
        },
        addStaffs: (state, action)=>{
            state.staffs = action.payload
        },
        addAdmins: (state, action)=>{
            state.admins = action.payload
        },
        addNotifications: (state, action)=>{
            state.notifications.push(action.payload)
        },
        clearNotifications: (state)=>{
            state.notifications = []
        },
    }
})

export const userLogin = (state)=>{
    return dispatch =>{
        dispatch(userSlice.actions.login(state))
    }
}

export const userLogout = ()=>{
    return dispatch =>{
        dispatch(userSlice.actions.logout())
    }
}

export const storeToken = (state)=>{
    return dispatch =>{
        dispatch(userSlice.actions.mailToken(state))
    }
}

export const handleUsers = (state)=>{
    return dispatch =>{
        dispatch(userSlice.actions.addUsers(state))
    }
}

export const handleStaffs = (state)=>{
    return dispatch =>{
        dispatch(userSlice.actions.addStaffs(state))
    }
}

export const handleAdmins = (state)=>{
    return dispatch =>{
        dispatch(userSlice.actions.addAdmins(state))
    }
}

export const handleAddNotifications = (state)=>{
    return dispatch =>{
        dispatch(userSlice.actions.addNotifications(state))
    }
}

export const handleClearNotifications = ()=>{
    return dispatch =>{
        dispatch(userSlice.actions.clearNotifications())
    }
}

export default userSlice.reducer