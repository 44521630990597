import {apiRequest} from '.'


export const addLocation = async (token, data) => {
    try{
        const response = await apiRequest({
            url: '/location',
            method: 'POST',
            data,
            token
        })

        return response
    }catch(error){
        console.log(error)
    }
}

export const getLocations = async (token) => {
    try{
        const response = await  apiRequest({
            url:'/location',
            token,
        });

        return response
    }catch(error){
        console.log(error)
    }
}

export const deleteLocation = async (token, id) => {
    try{
        const response = await  apiRequest({
            url:`/location/${id}`,
            method: 'DELETE',
            token,
        });

        return response
    }catch(error){
        console.log(error)
    }
}

export const updateLocation = async (token, id) => {
    try{
        const response = await apiRequest({
            url:`/location/${id}`,
            method: 'PUT',
            token,
        });

        return response
    }catch(error){
        console.log(error)
    }
}