import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    report: [],
    allReports: [],
    reportModal: {
        isOpen: false,
        data: {},
    }
}

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        addReport: (state, action)=>{
            state.report = action.payload
        },
        addAllReport: (state, action)=>{
            state.allReports = action.payload
        },
        openReportModal(state, action) {
            state.reportModal.isOpen = true;
            state.reportModal.data = action.payload;
        }, 
        closeReportModal(state) {
            state.reportModal.isOpen = false;
            state.reportModal.data = {};
        }
    }
})

export const handleAddReport= (state)=>{
    return dispatch => {
        dispatch(reportSlice.actions.addReport(state))
    }
}

export const handleAddAllReport= (state)=>{
    return dispatch => {
        dispatch(reportSlice.actions.addAllReport(state))
    }
}

export const handleOpenReportModal = (state) => {
    return dispatch => dispatch(reportSlice.actions.openReportModal(state))
};

export const handleCloseReportModal = () => dispatch => dispatch(reportSlice.actions.closeReportModal())

export default reportSlice.reducer