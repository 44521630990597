import React, { useEffect, useState } from 'react'
import './newsStand.css'
import Activities from '../../components/activities/Activities';
import NewsCard from '../../components/newsCard/NewsCard';
import { useDispatch, useSelector } from 'react-redux';
import { createPost, fetchPosts } from '../../api/postApi';
import { postState } from '../../state/postSlice';
import Loader from '../../components/loader/Loader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from 'react-router-dom';

// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);


const NewsStand = ({socket}) => {
    const {user} = useSelector((state)=>state.user)
    const {post} = useSelector((state)=>state.post)
    const [inputValue, setInputValue] = useState();
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [progress, setProgress] = useState(false)


    const handleGetPost = async ()=>{
        setProgress(true)
        try {
            const res = await fetchPosts(user?.token)
            dispatch(postState(res?.data))
            setProgress(false)
        } catch (error) {
            console.log(error)
            setProgress(false)
        }
    }

    useEffect(()=>{
        socket.on('receive-post',()=>{
            handleGetPost()
        })
    },[])

    useEffect(()=>{
        handleGetPost()
    },[])

    const handleCreatePost = async () =>{
        setIsLoading(true)
        try {
            if(!inputValue){
                MySwal.fire({
                    icon: 'warning',
                    title: "Enter post description",
                    showConfirmButton: false,
                    timer: 2000
                });
            }
            const data = {description: inputValue}
            const res = await createPost(user?.token, data)
            const socketData = {
                user: user?._id,
                userName: user?.firstName + ' ' + user?.lastName,
                message : `created a new Post`,
                date: moment().format('H:mm:ss A')
            }
            socket.emit("notification", socketData);
            socket.emit("create-post", socketData);
            if(res?.success){
                handleGetPost()
                MySwal.fire({
                    icon: 'success',
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 2000
                });
            }else{
                MySwal.fire({
                    icon: 'error',
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
            MySwal.fire({
                icon: 'error',
                title: "There's an error processing your request, Try again",
                showConfirmButton: false,
                timer: 2000
            });
        }
    }

    return (
        <section className='news__stand'>
            {/* HEADING */}
            <div className="heading">
                <div className="heading__box">
                    <div className="heading-item">
                        <h3 className="heading-title">News Stand | </h3>
                        <i class='bx bx-home heading__title-icon'></i>
                        <Link to="/" className="heading-text">
                            Home <i class='bx bx-chevron-right heading-icon'></i>
                        </Link>
                        <span className="heading-text">
                            News Stand <i class='bx bx-chevron-right heading-icon'></i>
                        </span>
                    </div>
                    <p className="heading-subtitle">
                        Stay up to date with the announcements.
                    </p>
                </div>
            </div>

            {/* PROGRESS BAR */}
            {progress && <LinearProgress sx={{width: '100%'}}/>}

            <div className="news__stand-body">

                <div className="news__stand-box">

                    <div className="news__stand-box_form">

                        <div className="news__stand-box_form-field">

                            <ReactQuill
                                name="description"
                                theme="snow"
                                className="news__stand-editor"
                                value={inputValue}
                                onChange={(content) => setInputValue(content)}
                            />
                            
                        </div>
                        {
                            isLoading ? <Loader/> : <button onClick={handleCreatePost} 
                            className="news__stand-box__form-button btn">
                                Submit
                            </button>
                        }

                    </div>

                    <div className="news__stand-item">
                        <h4 className="news__stand-item_title">
                            Latest Announcement:
                        </h4>
                        <NewsCard posts={post} user={user} 
                        socket={socket}
                        handleGetPost={handleGetPost}/>
                    </div>
                </div>

                <div className="news__stand-list">
                    <Activities/>
                </div>
            </div>


        </section>
    )
}

export default NewsStand
