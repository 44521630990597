import React, {useEffect} from 'react'
import './totalStaffs.css'
import TotalStaffChart from '../charts/TotalStaffs'
import { useDispatch, useSelector } from 'react-redux'
import { handleStaffs } from '../../state/userSlice'
import { getAllUsers, } from '../../api'
import { handleDepartment } from '../../state/deptSlice'
import { fetchDepartment } from '../../api/deptApi'

const TotalStaffs = () => {
    const {user, staffs} = useSelector((state)=> state.user)
    const {department} = useSelector((state)=>state.department)
    const dispatch = useDispatch()

    const handleFetchUsers = async () => {
        try{
            const res = await getAllUsers(user?.token)
            dispatch(handleStaffs(res?.data))
        }catch(error){
            console.log(error)
        }
    }

    const handleGetDept = async () =>{
        try{
            const res = await fetchDepartment(user?.token)
            dispatch(handleDepartment(res?.data))
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        handleFetchUsers()
        handleGetDept()
    },[])

    const genderCounts = staffs?.reduce((count, user)=>{
        count[user.gender] = (count[user.gender] || 0) + 1;
        return count
    }, {})

    const result = genderCounts ? Object.entries(genderCounts)?.map((item)=>item[1]) : []
    
  return (
    <div className="total__staff">
        <div className="total__staff-heading">
            <div className="total__staff-heading_item">
                <h4 className="total__staff-title">
                    TOTAL EMPLOYEES
                </h4>
                <span className="total__staff-count_total">
                    {staffs?.length}
                </span>
                <span className="total__staff-count_dep">
                    {department?.length} Departments
                </span>
            </div>
        </div>
        <div className="total__staff-content">
            <TotalStaffChart result={result}/>
        </div>
    </div>
  )
}

export default TotalStaffs