import { apiRequest } from "."


export const createPost = async (token, data)=>{
    try {
        const response = await apiRequest({
            url: '/post',
            method: 'POST',
            data: data,
            token: token,
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const fetchPosts = async (token)=>{
    try {
        const response = await apiRequest({
            url: '/post',
            token: token,
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const likePosts = async (token, postId)=>{
    try {
        const response = await apiRequest({
            url: `/post/like/${postId}`,
            method: 'POST',
            token: token,
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const commentPost = async (token, postId, data)=>{
    try {
        const response = await apiRequest({
            url: `/post/comment/${postId}`,
            method: 'POST',
            data: data,
            token: token,
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const fetchComments = async (token, postId)=>{
    try {
        const response = await apiRequest({
            url: `/post/comment/${postId}`,
            token: token,
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const deletePost = async (token, postId)=>{
    try {
        const response = await apiRequest({
            url: `/post/${postId}`,
            method: 'DELETE',
            token: token,
        })

        return response
    } catch (error) {
        console.log(error)
    }
}