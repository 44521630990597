import React, {useState} from 'react'
import './topbar.css'

import { RiMenu2Line } from "react-icons/ri";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FiMessageCircle } from "react-icons/fi";

import profile from '../../assets/profile.png'

import { useDispatch, useSelector } from 'react-redux'
import { toggleMessage, toggleNotification, toggleSideBar } from '../../state/settingsSlice'
import { userLogout } from '../../state/userSlice'

import { Link } from 'react-router-dom'

// PROFILE BOX
const TopbarDropDown = ({setDropDown, handleLogout}) => {

  return(
      <div className='topbar__dropdown' 
      onClick={()=>setDropDown(false)}>
          <div onClick={(e)=>e.stopPropagation()}
          className="topbar__dropdown-content">
              <Link to={'/'} 
              className='topbar__dropdown-link'>
                  Profile
              </Link>
              <Link onClick={handleLogout}
              className='topbar__dropdown-link'>
                  Logout
              </Link>
          </div>
      </div>
  )
}

const Topbar = ({message}) => {
  const {user, notifications} = useSelector((state)=>state.user)
  const {sidebar, notification, notifyMessage} = useSelector((state)=>state.settings)
  const dispatch = useDispatch()
  const [dropDown, setDropDown] = useState(false)

  const handleSidebar = () =>{
    dispatch(toggleSideBar(!sidebar))
  }

  const handleNotification = () => {
    dispatch(toggleNotification(!notification))
  }

  const handleMessage = () => {
    dispatch(toggleMessage(!notifyMessage))
  }

  const handleLogout = () =>{
    dispatch(userLogout())
  }

  const filterNotification = notifications?.filter((item)=>{
      return item?.user !== user?._id
  })

  return (
    <div className={`topbar ${!sidebar ? 'active' : ''}`}>
      <div className="topbar__container">
        <button onClick={handleSidebar}
        className="topbar__btn">
          <RiMenu2Line className='topbar__icon'/>
        </button>
        <div className="topbar__content">
          <button className="topbar__btn" onClick={handleNotification}>
            <span className="topbar__span">
              {filterNotification?.length || 0}
            </span>
            <IoMdNotificationsOutline className='topbar__icon'/>
          </button>
          <button className="topbar__btn" onClick={handleMessage}>
            <span className="topbar__span">
              {message?.length || 0}
            </span>
            <FiMessageCircle className='topbar__icon'/>
          </button>
          <img src={profile} onClick={()=>setDropDown(!dropDown)}
          alt="Profile" 
          className="topbar__img" />
        </div>
      </div>

      {dropDown && <TopbarDropDown setDropDown={setDropDown}
        handleLogout={handleLogout}/>}
    </div>
  )
}

export default Topbar