import React from 'react'
import './messageBox.css'
import profile from '../../assets/profile.png'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toggleMessage } from '../../state/settingsSlice'
import { handleClearMess } from '../../state/chatSlice'

const MessageBox = () => {
    const dispatch = useDispatch()
    const {notifyMessage} = useSelector((state)=>state.settings)
    const {message} = useSelector((state)=>state.chat)

    const handleMessage = () => {
        dispatch(toggleMessage(!notifyMessage))
    }

    const handleClearAll = () => {
        dispatch(handleClearMess())
    }
  return (
    <div className={`message ${notifyMessage && 'active'}`}>
        <div className={`message__overlay`} onClick={handleMessage}></div>
        
        <div className="message__box">
            <div className="message__top">
                <h3 className="message__title">Messages</h3>
                <button onClick={handleClearAll}
                className="message__btn">
                    Clear all
                </button>
            </div>

            <div className="message__list">
                {
                    message?.map((item, i)=>(
                        <Link to="/chats" key={i} className="message__items">
                            <img src={profile} alt="" className="message__img" />
                            <div className="message__div">
                                <h4 className="message__name">
                                    {item?.senderName}
                                </h4>
                                <span className="message__text">
                                    {item?.text}
                                </span>
                            </div>
                        </Link>
                    ))
                }
            </div>
            
            <div className="message__bottom">
                {
                    message?.length > 0 &&
                    <Link to="/chat" className="message__bottom-link">
                        View all messages
                    </Link>
                }
            </div>
        </div>

    </div>
  )
}

export default MessageBox