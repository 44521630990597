import { apiRequest } from "."


export const submitLeave = async (token, data)=>{
    try {
        const response = await apiRequest({
            method: 'POST',
            url: '/leave',
            data: data,
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const getLeaves = async (token)=>{
    try {
        const response = await apiRequest({
            url: '/leave/user',
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const getAllLeaves = async (token)=>{
    try {
        const response = await apiRequest({
            url: '/leave',
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const updateLeaveRequest = async (token, data, id)=>{
    try {
        const response = await apiRequest({
            url: `/leave/${id}`,
            method: 'PUT',
            data: data,
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const leaveBalance = async (token)=>{
    try {
        const response = await apiRequest({
            url: `/leave/balance`,
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const staffsOnLeave = async (token)=>{
    try {
        const response = await apiRequest({
            url: `/leave/staffs`,
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}