import React, {useEffect, useState} from 'react';
import './charts.css'
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'

import {userAttendance, getAllAcounts} from '../../api'
import {getAllAdminReports} from '../../api/reportApi'
import {getAllAllStaffTasks} from '../../api/taskApi'

import { useDispatch, useSelector } from 'react-redux'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
)


const fetchData = async (fetchFunction, setData) => {
    try {
        const res = await fetchFunction();
        setData(res?.data);
    } catch (error) {
        console.error(error); // Log the error for debugging
    }
}

const fetchAttendance = async (user, setAttendance) => {
    await fetchData(() => userAttendance(user?.token), setAttendance);
}

const fetchTasks = async (user, setTasks) => {
    await fetchData(() => getAllAllStaffTasks(user?.token), setTasks);
}

const fetchReports = async (user, setReports) => {
    await fetchData(() => getAllAdminReports(user?.token), setReports);
}

const fetchStaffs = async (user, setStaffs) => {
    await fetchData(() => getAllAcounts(user?.token), setStaffs);
}

const getWorkingDaysInMonth = (year, month) => {
    const daysInMonth = new Date(year, month, 0).getDate();
    let workingDays = 0;
    for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = new Date(year, month - 1, day);
        const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Exclude Sundays and Saturdays
            workingDays++;
        }
    }
    return workingDays;
};

const calculatePerformanceForMonths = (tasks, reports, attendance, totalStaffs) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Month indexes are zero-based, so we add 1
    const performanceForMonths = Array(currentMonth).fill(0);

    for (let month = 1; month <= currentMonth; month++) {
        // Get the number of working days in the current month
        const workingDays = getWorkingDaysInMonth(currentYear, month);

        // Filter tasks for the current month and year
        const tasksForMonth = tasks?.filter(task => {
            const taskDate = new Date(task?.createdAt);
            return taskDate.getMonth() + 1 === month && taskDate.getFullYear() === currentYear;
        });

        // Filter reports for the current month and year
        const reportsForMonth = reports?.filter(report => {
            const reportDate = new Date(report?.createdAt);
            return reportDate.getMonth() + 1 === month && reportDate.getFullYear() === currentYear;
        });

        // Find attendance records for the current month and year
        const attendanceForMonth = attendance?.filter(record => {
            const recordDate = new Date(record?.createdAt);
            return recordDate.getMonth() + 1 === month && recordDate.getFullYear() === currentYear;
        });

        // Calculate attendance days for the current month for each user
        let totalAttendanceDays = 0;
        attendanceForMonth?.forEach(record => {
            totalAttendanceDays += (record?.days || []).filter(day => {
                // Check if the user was present or any other status that indicates work
                return day?.status === 'Present' || day?.status === 'On Leave' || day?.status === 'Late';
            }).length;
        });

        // Calculate the percentage of tasks, reports, and attendance
        const tasksPercentage = (tasksForMonth?.reduce((total, task) => total + (task?.todolist?.length || 0), 0) / (totalStaffs?.length * workingDays)) * 100;
        const reportsPercentage = ((reportsForMonth?.reduce((total, report) => total + (report?.reports?.length || 0), 0)) / (totalStaffs?.length * workingDays)) * 100;
        const attendancePercentage = (totalAttendanceDays / (totalStaffs?.length * workingDays)) * 100;

        // Adjust weights for each metric as needed
        const performance = (tasksPercentage * 0.3) + (reportsPercentage * 0.3) + (attendancePercentage * 0.4);

        performanceForMonths[month - 1] = performance;
    }

    return performanceForMonths;
};


const PerformanceChart = () => {
    const {user} = useSelector((state)=>state.user)
    const [attendance, setAttendance] = useState([])
    const [reports, setReports] = useState()
    const [tasks, setTasks] = useState()
    const [staffs, setStaffs] = useState()


//     const fetchAttendance = async ()=>{
//         try{
//             const res = await userAttendance(user?.token)
//             setAttendance(res?.data)
//         }catch(error){
//             console.log(error)
//         }
//     }

//     const fetchTasks = async ()=>{
//         try{
//             const res = await getAllAllStaffTasks(user?.token)
//             setTasks(res?.data)
//         }catch(error){
//             console.log(error)
//         }
//     }

//     const fetchReports = async ()=>{
//         try{
//             const res = await getAllAdminReports(user?.token)
//             setReports(res?.data)
//         }catch(error){
//             console.log(error)
//         }
//     }

//     const fetchStaffs = async ()=>{
//         try{
//             const res = await getAllAcounts(user?.token)
//             setStaffs(res?.data)
//         }catch(error){
//             console.log(error)
//         }
//     }


//     useEffect(()=>{
//         fetchReports()
//         fetchTasks()
//         fetchAttendance()
//         fetchStaffs()
//     },[])


//     // Function to calculate the number of working days in a month, excluding weekends
// const getWorkingDaysInMonth = (year, month) => {
//     const daysInMonth = new Date(year, month, 0).getDate();
//     let workingDays = 0;
//     for (let day = 1; day <= daysInMonth; day++) {
//       const currentDate = new Date(year, month - 1, day);
//       const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
//       if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Exclude Sundays and Saturdays
//         workingDays++;
//       }
//     }
//     return workingDays;
//   };
  
//   // Function to calculate performance data for each month
// const calculatePerformanceForMonths = (tasks, reports, attendance, totalStaffs) => {
//     const currentYear = new Date().getFullYear();
//     const currentMonth = new Date().getMonth() + 1; // Month indexes are zero-based, so we add 1
//     const performanceForMonths = Array(currentMonth).fill(0);
  
//     for (let month = 1; month <= currentMonth; month++) {
//       // Get the number of working days in the current month
//       const workingDays = getWorkingDaysInMonth(currentYear, month);
  
//       // Filter tasks for the current month and year
//       const tasksForMonth = tasks?.filter(task => {
//         const taskDate = new Date(task?.createdAt);
//         return taskDate.getMonth() + 1 === month && taskDate.getFullYear() === currentYear;
//       });
  
//       // Filter reports for the current month and year
//       const reportsForMonth = reports?.filter(report => {
//         const reportDate = new Date(report?.createdAt);
//         return reportDate.getMonth() + 1 === month && reportDate.getFullYear() === currentYear;
//       });
  
//       // Find attendance records for the current month and year
//     const attendanceForMonth = attendance?.filter(record => {
//         const recordDate = new Date(record?.createdAt);
//         return recordDate.getMonth() + 1 === month && recordDate.getFullYear() === currentYear;
//       });
  
//       // Calculate attendance days for the current month for each user
//       let totalAttendanceDays = 0;
//       attendanceForMonth?.forEach(record => {
//         totalAttendanceDays += record?.days.filter(day => {
//           // Check if the user was present or any other status that indicates work
//           return day?.status === 'Present' || day?.status === 'On Leave' || day?.status === 'Late';
//         })?.length;
//       });
  
//   let performance = 0;
  
//   // Calculate the percentage of tasks, reports, and attendance
//       const tasksPercentage = (tasksForMonth?.reduce((total, task) => total + task?.todolist?.length, 0) / (totalStaffs?.length * workingDays)) * 100;
//       const reportsPercentage = ((reportsForMonth?.reduce((total, report) => total + report?.reports?.length, 0)) / (totalStaffs?.length * workingDays)) * 100;
//       const attendancePercentage = (totalAttendanceDays / (totalStaffs?.length * workingDays)) * 100;
  
//       // Adjust weights for each metric as needed
//       performance = (tasksPercentage * 0.3) + (reportsPercentage * 0.3) + (attendancePercentage * 0.4);
  
//       performanceForMonths[month - 1] = performance;
//     }
  
//     return performanceForMonths;
//   };

//       const performanceForMonths = calculatePerformanceForMonths(tasks, reports, attendance, staffs);

    useEffect(() => {
        // Fetch data when component mounts
        const fetchData = async () => {
            // Assuming user state is accessible
            await Promise.all([
                fetchAttendance(user, setAttendance),
                fetchTasks(user, setTasks),
                fetchReports(user, setReports),
                fetchStaffs(user, setStaffs)
            ]);
        };

        fetchData();
    }, [user]); // Adjust dependencies as per your project requirements

    const performanceForMonths = calculatePerformanceForMonths(tasks, reports, attendance, staffs);

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 
        'June', 'July', 'Aughust', 'September', 'October', 'November', 'December'],
        datasets: [{
                label: 'Staffs Performance',
                data: performanceForMonths,
                borderWidth: 1,
                backgroundColor: "rgb(36,223,179)",
                borderRadius: '10px',
            }]
        }
    
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    fontSize: 16,
                }
            },
            title: {
                display: true,
                text: 'Staffs Performance Statistics', 
                position: 'top',
                fontSize: 20,
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 100, 
                ticks: {
                    callback: function (value) {
                        return '%' + value;
                    },
                },
                
            },
        },
    
    }
   
  return (
    <div className="charts__performance">
        <h4 className="charts__performance-title">
            Staffs Monthly Performance Summary
        </h4>
        <div className="charts__performance-box"> 
            <Bar data={data} options={options} width={1000}/>;
        </div>
    </div>
  )
};

export default PerformanceChart;
