import React, { useEffect, useState } from 'react'
import './appraisal.css'
import { appraisalTableData } from '../../utils'
import { Link, useParams } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import { fetchUserAppraisal, updateUserAppraisal } from '../../api/appraisalApi'
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);


const UpdateAppraisal = () => {
    const {user} = useSelector((state)=>state.user)
    const {id} = useParams()
    const [appraisalData, setAppraisalData] = useState()
    const [comment, setComment] = useState()
    const [scores, setScores] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    // Function to handle input change
    const handleInputChange = (rowIndex, colIndex, value) => {
        const updatedScores = [...scores];
        while (updatedScores.length <= rowIndex) {
            updatedScores.push([]);
        }
        updatedScores[rowIndex][colIndex] = value;
        setScores(updatedScores);
    };

    const handleFetchAppraisal = async ()=>{
        try {
            const res = await fetchUserAppraisal(user?.token, id)
            setAppraisalData(res?.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        handleFetchAppraisal()
    },[id])

    const handleUpdateAppraisal = async () =>{
        if(!comment || !scores){
            MySwal.fire({
                icon: 'warning',
                text: 'All fields are required!',
                showConfirmButton: false,
                timer: 3000
            });
        }else{
            setIsLoading(true)
            try{
                const flatScores = scores.flat();
                const data = {
                    appraisal: appraisalData?.appraisal?.map(data => ({
                        kra: data.kra,
                        kpi: data.kpi.map((item, index) => ({
                            name: item?.name,
                            score: user?.department === 'HR' 
                            ? { user: item?.score[0].user, hr: flatScores[index] || item?.score[0].hr, admin: item?.score[0].admin } 
                            : { user: item?.score[0].user, hr: item?.score[0].hr, admin: flatScores[index] || item?.score[0].admin }
                        }))
                    })),
                    achievement: appraisalData?.achievement,
                    innovation: appraisalData?.innovation,
                    hrComment: appraisalData?.hrComment,
                    mpComment: comment,
                }
                const res = await updateUserAppraisal(user?.token, data, id)
                if(res?.success){
                    setScores([])
                    handleFetchAppraisal()
                    MySwal.fire({
                        icon: 'success',
                        title: res?.message,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }else{
                    MySwal.fire({
                        icon: 'error',
                        title: res?.message,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
                setIsLoading(false)
            }catch(error){
                setIsLoading(false)
                console.log(error)
                MySwal.fire({
                    icon: 'error',
                    title: "There's an error processing your request, Try again",
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }
    }


  return (
    <section className='updateAppraisal'>
        {/* HEADING */}
        <div className="heading">
            <div className="heading__box">
                <div className="heading-item">
                    <h3 className="heading-title">Update Employee Appraisal | </h3>
                    <i class='bx bx-home heading__title-icon'></i>
                    <Link to="/" className="heading-text">
                        Home <i class='bx bx-chevron-right heading-icon'></i>
                    </Link>
                    <span className="heading-text">
                    Update Employee Appraisal <i class='bx bx-chevron-right heading-icon'></i>
                    </span>
                </div>
                <p className="heading-text">
                    View and Update  Employee’s Submitted Appraisal.
                </p>
            </div>
        </div>

        <div className="updateAppraisal__form">

            <div className="updateAppraisal__user-info">
                <h4 className="updateAppraisal__user-info__title">User Information</h4>
                <div className="updateAppraisal__user-item">
                    <div className="updateAppraisal__user-field">
                        <label htmlFor="name" className="updateAppraisal__user-label">
                            Name
                        </label>
                        <input type="text" className="updateAppraisal__user-input" 
                        id="name" name="name" 
                        value={appraisalData?.user?.firstName + ' ' + appraisalData?.user?.lastName}
                        readonly/>
                    </div>
                    <div className="updateAppraisal__user-field">
                        <label htmlFor="staffId" className="updateAppraisal__user-label">
                            Staff ID
                        </label>
                        <input type="text" className="updateAppraisal__user-input" 
                        id="name" name="staffId" value={appraisalData?.user?.staffId} readOnly/>
                    </div>
                    <div className="updateAppraisal__user-field">
                        <label htmlFor="name" className="updateAppraisal__user-label">
                            Department
                        </label>
                        <input type="text" className="updateAppraisal__user-input" 
                        id="name" name="name" 
                        value={appraisalData?.user?.department}
                        readonly/>
                    </div>
                    <div className="updateAppraisal__user-field">
                        <label htmlFor="staffId" className="updateAppraisal__user-label">
                            Role
                        </label>
                        <input type="text" className="updateAppraisal__user-input" 
                        id="name" name="staffId" value={appraisalData?.user?.role} readOnly />
                    </div>
                </div>
            </div>

            <div className="updateAppraisal__content">
                <h4 className="updateAppraisal__content-title">
                    Performance Rating/Scoring Criteria
                </h4>

                <div className="updateAppraisal__content-note">
                    <h4 className="updateAppraisal__content-note_title">
                        Key Factors
                    </h4>
                    <span className="updateAppraisal__content-note_span">
                        <span>5 = </span> Exceptional {'(Constantly exceeds position requirements – i.e. more than 90%)'}
                    </span>
                    <span className="updateAppraisal__content-note_span">
                        <span>4 =</span> Exceeded Expectations {'(Constantly meets and occasionally exceeds position requirements – i.e. 81 – 90%)'}
                    </span>
                    <span className="updateAppraisal__content-note_span">
                        <span>3 =</span> Satisfactory {'(Mostly meets the position requirements – i.e. 70 − 80% achievement)'}
                    </span>
                    <span className="updateAppraisal__content-note_span">
                        <span>2 =</span> Requires Improvement {'(Occasionally doesn’t meet minimum requirement – i.e. 50−69%)'}
                    </span>
                    <span className="updateAppraisal__content-note_span">
                        <span>1 =</span> Unsatisfactory {'(Does not meet minimum position requirement – i.e. <50% achievement)'}
                    </span>
                </div>
            </div>

            <div className="updateAppraisal__form-table">
                <h4 className="updateAppraisal__content-title">
                    Update Appraisal Table
                </h4>

                <div className='updateAppraisal__table-container'>
                    <table className="updateAppraisal__table">
                        <thead className='updateAppraisal__table__head'>
                            <tr>
                                <th>#</th>
                                <th>Key Result Area</th>
                                <th>Key Performance Indicator</th>
                                <th>Employee</th>
                                {
                                    user?.department === 'HR' ? (
                                        <th>HR</th>
                                    ) : user?.role === 'Administrator' ? (
                                        <>
                                            <th>HR</th>
                                            <th>MP</th>
                                        </>
                                    ) : null
                                }
                            </tr>
                        </thead>
                        <tbody className='updateAppraisal__table__body'>
                            {
                                appraisalData?.appraisal?.map((data, rowIndex)=>(
                                    <tr className='updateAppraisal__table-parent_tr' key={rowIndex}>
                                        <td className='updateAppraisal__flex-1'>{rowIndex + 1}</td>
                                        <td className='updateAppraisal__flex-2'>{data?.kra}</td>
                                        <td className='updateAppraisal__flex-3'>
                                            <tr className='updateAppraisal__table-child_tr'>
                                                {
                                                    data?.kpi?.map((item, i)=>(
                                                        <td key={i}>{item?.name}</td>
                                                    ))
                                                }
                                            </tr>
                                        </td>
                                        <td className='updateAppraisal__flex-4'>
                                            <tr className='updateAppraisal__table-child_tr'>
                                                {
                                                    data?.kpi?.map((item, i)=>(
                                                        <td key={i}>
                                                            <input type="number" className="updateAppraisal__table-input" 
                                                            value={item?.score[0]?.user} readOnly/>
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        </td>
                                        {
                                            user?.department === 'HR' ? (
                                                <td className='updateAppraisal__flex-4'>
                                                    <tr className='updateAppraisal__table-child_tr'>
                                                        {
                                                            data?.kpi?.map((item, colIndex)=>(
                                                                <td key={colIndex}>
                                                                    <input type="number" 
                                                                    className="updateAppraisal__table-input" 
                                                                    id={`staffScore_${rowIndex}_${colIndex}`}
                                                                    value={scores[rowIndex]?.[colIndex]}
                                                                    defaultValues={item?.score[0]?.hr}
                                                                    onChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)}
                                                                    min="1"
                                                                    max="5"
                                                                    required/>
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                </td>
                                            ) : user?.role === 'Administrator' ? (
                                                <>
                                                    <td className='updateAppraisal__flex-4'>
                                                        <tr className='updateAppraisal__table-child_tr'>
                                                            {
                                                                data?.kpi?.map((item, i)=>(
                                                                    <td key={i}>
                                                                        <input type="number" 
                                                                        className="updateAppraisal__table-input" 
                                                                        value={item?.score[0]?.hr}/>
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    </td>

                                                    <td className='updateAppraisal__flex-4'>
                                                        <tr className='updateAppraisal__table-child_tr'>
                                                            {
                                                                data?.kpi?.map((item, colIndex)=>(
                                                                    <td key={colIndex}>
                                                                        <input type="number" 
                                                                        className="updateAppraisal__table-input" 
                                                                        id={`staffScore_${rowIndex}_${colIndex}`}
                                                                        value={scores[rowIndex]?.[colIndex]}
                                                                        defaultValues={item?.score[0]?.admin}
                                                                        onChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)}
                                                                        min="1"
                                                                        max="5"
                                                                        required/>
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    </td>
                                                </>
                                            ) : null }
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>

                <div className="appraisal__form-others">
                    <div className="appraisal__form-others_item" >
                        <label htmlFor="achievement" className="appraisal__form-others-label">
                            Staff Achievement
                        </label>
                        <p className="appraisal__form-others_desc"
                        dangerouslySetInnerHTML={{__html: appraisalData?.achievement}}>
                        </p>
                    </div>
                    <div className="appraisal__form-others_item">
                        <label htmlFor="achievement" className="appraisal__form-others-label">
                            Staff Innovation
                        </label>
                        <p className="appraisal__form-others_desc"
                        dangerouslySetInnerHTML={{__html: appraisalData?.innovation}}>
                        </p>
                    </div>
                    {appraisalData?.hrComment && 
                        <div className="appraisal__form-others_item" >
                            <label htmlFor="achievement" className="appraisal__form-others-label">
                                HR Comments
                            </label>
                            <p className="appraisal__form-others_desc"
                            dangerouslySetInnerHTML={{__html: appraisalData?.hrComment}}>
                            </p>
                        </div>
                    }
                    {appraisalData?.mpComment && 
                        <div className="appraisal__form-others_item">
                            <label htmlFor="achievement" className="appraisal__form-others-label">
                                MP Comments
                            </label>
                            <p className="appraisal__form-others_desc"
                            dangerouslySetInnerHTML={{__html: appraisalData?.mpComment}}>
                            </p>
                        </div>
                    }
                </div>

                <div className="appraisal__form-item">
                    <label htmlFor="comments" className="appraisal__form-label">
                        Add Comments
                    </label>
                    <ReactQuill
                    name="issue"
                    theme="snow"
                    value={comment}
                    onChange={(content) => setComment(content)}
                    style={{minWidth: '100%'}}
                    />
                </div>

                <div className="updateAppraisal__form-item">
                    {isLoading ? <Loader/> : 
                    <button type="submit" 
                    onClick={()=>handleUpdateAppraisal()}
                    className="updateAppraisal__form-btn btn">
                        Submit Appraisal
                    </button>
                    }
                </div>

            </div>

        </div>
    </section>
  )
}

export default UpdateAppraisal