import React, {useEffect} from 'react'
import './activities.css'

import { useSelector, useDispatch } from 'react-redux';
import {fetchPosts} from '../../api/postApi'

import moment from 'moment'

import profile from '../../assets/profile.png'
import { postState } from '../../state/postSlice';


const Activities = () => {
    const {user} = useSelector((state)=>state.user)
    const {post} = useSelector((state)=>state.post)
    const dispatch = useDispatch()

    const handleGetPost = async ()=>{
        try {
            const res = await fetchPosts(user?.token)
            dispatch(postState(res?.data))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        handleGetPost()
    },[])

    return (
        <div className="activities__card">
            <div className="activities__card-header">
                <h3 className="activities__card-title">
                    Recent Activities
                </h3>
                <span className="activities__card-count">
                    {post ? post.length : 0}
                </span>
            </div>
            <div className="activities__card-content">
                {
                    post?.slice(0, 2)?.map((item, i)=>(
                        <div className="activities__card-items" key={i}>
                            <img src={profile} className="activities__card-img"/>
                            <div className="abdsent__card-div">
                                <h3 className="activities__card-name">
                                    {item?.user?.firstName + ' ' + item?.user?.lastName}
                                </h3>
                                <p className="activities__card-text" 
                                dangerouslySetInnerHTML={{__html: item?.description?.length > 80 ?
                                    item?.description?.slice(0, 80)+'...' : item?.description }}>
                                </p>
                                <span className="activities__card-time">
                                    {moment(item?.createdAt).fromNow()}
                                </span>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Activities