import React, { useEffect, useRef, useState } from 'react'
import './newsCard.css'
import profile from '../../assets/profile.png'
import moment from 'moment'
import { commentPost, deletePost, fetchComments, likePosts } from '../../api/postApi'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);



const NewsCardMore = ({userId, postId, viewMore, setViewMore, index, handleGetPost}) => {

    const handleDeletePost = async (postId) =>{
        try{
            MySwal.fire({
                title: 'Are you sure?',
                text: 'Do you want to proceed with this action?',
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: true, // Disable the confirmation button
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes, proceed',
                cancelButtonColor: '#d33'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // User clicked "OK", proceed with the action
                    const res = await deletePost(userId, postId);
                    handleGetPost()
                    MySwal.fire({
                        icon: res?.success ? 'success' : 'error',
                        title: res?.message,
                        showConfirmButton: false,
                        timer: 2000
                    });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    MySwal.fire({
                        icon: 'success',
                        title: 'Your post is safe!',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            });
        }catch(error){
            console.log(error)
            MySwal.fire({
                icon: 'error',
                title: "There's an error processing your request, Try again",
                showConfirmButton: false,
                timer: 2000
            });
        }
    }

    return (
        <>
            <div className={`news__card-more_overlay 
            ${viewMore !== null ? 'active' : ''}`}
            onClick={()=>setViewMore(null)}></div>
            <div className={`news__card-more ${viewMore === index ? 'active' : ''}`}>
                <button onClick={()=>handleDeletePost(postId)}
                className="news__card-more_button">Delete</button>
            </div>
        </>
    )
}

const CommentBox = ({user, postId, postUser, 
    handleGetComment, handleGetPost, socket}) => {
        const [isLoading, setIsLoading] = useState(false)
    const {
        register,
        handleSubmit,
        formState:{errors},
        reset
    } = useForm({
        mode: 'onChange'
    })
    const handleComment = async (data) =>{
        setIsLoading(true)
        try {
            const res = await commentPost(user?.token, postId, data)
            const socketData = {
                user: user?._id,
                userName: user?.lastName,
                comment: true,
                postOwnerId: postUser?._id,
                postOwnerName: postUser?.firstName
            }
            socket.emit('notification', socketData)
            socket.emit('comment-post', postId)
            handleGetComment(postId)
            handleGetPost()
            reset()
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    return (
        <div>
            <div className='comment__box'>
                <input type='text' 
                name='comment'
                {...register('comment',{required: 'This field is required!'})}
                placeholder='Add a comment...' 
                className='comment__box-input'/>
                <button onClick={handleSubmit(handleComment)}
                disabled={isLoading}
                className={`comment__box-button btn ${isLoading ? 'active' : ''}`}>
                    {isLoading ? 
                    <i class='bx bx-rotate-right comment__box-icon'></i> 
                    : 'Submit'}
                </button>
            </div>
            {
                errors.comment && 
                <span className="error__message">
                    {errors.comment.message}
                </span>
            }
            
        </div>
    )
}

const Comment = ({comment}) => {

    return (
        <div className='comment'>
            <div className="comment__card">
                <div className="comment__card-heading">
                    <div className="comment__card-profile">
                        <img src={profile} alt="" className="comment__card-img"/>
                        <div className="comment__card-detail">
                            <h4 className="comment__card-name">
                                {comment?.user?.firstName + ' ' + comment?.user?.lastName}
                            </h4>
                            <span className="comment__card-role">
                                {comment?.user?.department + ' ' + comment?.user?.role}
                            </span>
                        </div> 
                    </div>
                    <span className="comment__card-date">
                        {moment(comment?.createdAt).format('DD MMMM YYYY HH:mm:ss A')}
                    </span>
                </div>
                <p className='comment__card-desc'>
                    {comment?.comment}
                </p>
            </div>
        </div>
    )
}


const NewsCard = ({posts, user, handleGetPost, socket}) => {
    const [viewMore, setViewMore] = useState(null)
    const [showComment, setShowComment] = useState(null)
    const [commentArray, setCommentArray] = useState([])
    const editorRef = useRef(null);

    const handleToggleViewMore = (val) => {
        if(viewMore === val){
            setViewMore(null)
        }else{
            setViewMore(val)
        }
    }

    const handleGetComment = async (val) => {
        try {
            const res = await fetchComments(user?.token, val)
            setCommentArray(res?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleToggleComment = async (val) => {
        if(showComment === val){
            setShowComment(null)
        }else{
            setShowComment(val)
        }

        handleGetComment(val)
    }
    
    useEffect(()=>{
        socket.on('receive-comment',(data)=>{
            handleGetComment(data)
        })
    },[])

    const handleLikes = async (id, item) =>{
        try {
            await likePosts(user?.token, id)
            const isLiked = item?.likes?.includes(user?._id)
            if(!isLiked){
                const socketData = {
                    user: user?._id,
                    userName: user?.firstName + ' ' + user?.lastName,
                    like: true,
                    postOwnerId: item?.user?._id,
                    postOwnerName: item?.user?.firstName
                }
                socket.emit('notification', socketData)
            }
            handleGetPost()
        } catch (error) {
            console.log(error)
        }
    }

  return (
        posts?.map((item, i)=>(
            <div className='news__card' key={i}>
                <div className="news__card-top">
                    <div className="news__card-profile">
                        <img src={profile} alt="" className="news__card-profile_img" />
                        <div className="news__card-profile_detail">
                            <h4 className="news__card-profile_name">
                                {item?.user?.firstName + ' ' + item?.user?.lastName}
                            </h4>
                            <span className="news__card-profile_role">
                                {item?.user?.department}
                            </span>
                            <span className="news__card-profile_date">
                                {moment(item?.createdAt).fromNow()}
                            </span>
                        </div>
                    </div>
                    <i class='bx bx-dots-vertical-rounded news__card-icon' 
                    onClick={()=>handleToggleViewMore(i)}></i>
                </div>
                <NewsCardMore userId={user?.token} postId={item?._id} 
                viewMore={viewMore} index={i} setViewMore={setViewMore}
                handleGetPost={handleGetPost}/>
                <div className="news__card-content" 
                dangerouslySetInnerHTML={{ __html: item?.description }}>
                </div>
                <div className="news__card-bottom">
                    <div className="news__card-bottom_item">
                        <button onClick={()=>handleLikes(item?._id, item)}
                        className="news__card-bottom_button">
                            {item?.likes?.includes(user?._id) ? (
                                <i class='bx bxs-heart news__card-bottom_icon'></i>
                                ) : (
                                <i class='bx bx-heart news__card-bottom_icon'></i>
                            )}
                        </button> 
                        &nbsp;
                        <span className='news__card-bottom_count'>
                            {item?.likes?.length}
                        </span>
                    </div>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <div className="news__card-bottom_item">
                        <button className="news__card-bottom_button" 
                        onClick={()=>handleToggleComment(item?._id)}>
                            <i class='bx bx-message-dots news__card-bottom_icon'></i>
                        </button> 
                        &nbsp;
                        <span className='news__card-bottom_count'>{item?.comment?.length}</span>
                    </div>
                </div>
                <div className={`news__card-comment 
                ${showComment === item?._id ? 'active' : ''}`}>
                    <CommentBox user={user} postId={item?._id} 
                    postUser={item?.user} socket={socket}
                    handleGetPost={handleGetPost}
                    handleGetComment={handleGetComment}/>
                    {
                        commentArray?.map((comm, i)=>(
                            <Comment comment={comm} key={i}/>
                        ))
                    }
                </div>
            </div>
        ))
  )
}

export default NewsCard