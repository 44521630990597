import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    modal: {
        show: false,
        data: {}
    }
}


const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal(state, action) {
            state.modal.show = true;
            state.modal.data = action.payload;
        }, 
        closeModal(state) {
            state.modal.show = false;
            state.modal.data = {};
        }
    }
})


export const handleOpenModal = (state) => {
    return dispatch => dispatch(modalSlice.actions.openModal(state))
};

export const handleCloseModal = () => dispatch => dispatch(modalSlice.actions.closeModal())

export default modalSlice.reducer;