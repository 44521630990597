import React from 'react'
import './paymentApproval.css'
import { Link } from 'react-router-dom';
import moment from 'moment'
import profile from '../../assets/profile.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import LinearProgress from '@mui/material/LinearProgress';
import PaymentTable from '../../components/tables/payment/Payment';

// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);

const PaymentApproval = () =>{


    return(
        <section className='payment__approval'>
            {/* HEADING */}
            <div className="heading">
                <div className="heading__box">
                    <div className="heading-item">
                        <h3 className="heading-title">Payment Approval | </h3>
                        <i class='bx bx-home heading__title-icon'></i>
                        <Link to="/" className="heading-text">
                            Home <i class='bx bx-chevron-right heading-icon'></i>
                        </Link>
                        <span className="heading-text">
                            Payment Approval<i class='bx bx-chevron-right heading-icon'></i>
                        </span>
                    </div>
                </div>
            </div>

            <div className="payment__approval-content">
                <div className="payment__approval-header">
                    <h1 style={{fontSize: 'var(--fs-large)',color: 'var(--white-gray-color)'}}>
                        STILL UNDER DEVELOPMENT!
                    </h1>
                </div>
                <PaymentTable/>
            </div>

        </section>
    )
}


export default PaymentApproval