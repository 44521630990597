import { apiRequest } from ".";


export const submitAppraisal = async (token, data) =>{
    try {
        const response = await apiRequest({
            url: '/appraisal',
            method: 'POST',
            data: data,
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const fetchAppraisal = async (token) =>{
    try {
        const response = await apiRequest({
            url: '/appraisal',
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const fetchUserAppraisal = async (token, id) =>{
    try {
        const response = await apiRequest({
            url: `/appraisal/user/${id}`,
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

export const fetchSingleUserAppraisal = async (token) =>{
    try {
        const response = await apiRequest({
            url: `/appraisal/user`,
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}

// Update appraisal for a specific user
export const updateUserAppraisal = async (token, data, id) =>{
    try {
        const response = await apiRequest({
            url: `/appraisal/${id}`,
            method: 'PUT',
            data: data,
            token: token
        })

        return response
    } catch (error) {
        console.log(error)
    }
}