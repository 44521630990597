import React, { useState, useEffect } from 'react'
import './requestStatus.css'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { getAllLeaves, updateLeaveRequest } from '../../api/leaveApi'
import { useSelector, useDispatch } from 'react-redux'
import { handleAddUserLeave } from '../../state/leaveSlice'
import moment from 'moment'
import profile from '../../assets/profile.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import LinearProgress from '@mui/material/LinearProgress';
// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);


function ExpandableCell({ value }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div style={{display: 'flex',flexDirection: 'column'}}>
      <div style={{color: 'var(--white-gray-color)'}}
      dangerouslySetInnerHTML={{__html: expanded ? value : value?.slice(0, 70)+'...'}}>
      </div>
      {value?.length > 100 && (
        <button
          className="request__status-table_btn"
          type="button"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'view less' : 'view more'}
        </button>
      )}
    </div>
  );
}

const ActionCell = ({param}) => {
  const {user} = useSelector((state)=>state.user)
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()

  const handleUpdateRequest = async (value)=>{
    setLoader(true)
    try{
      const isAdmin = user?.role === 'Administrator';
      const isSupervisor = user?.role === 'Supervisor';
      const isSameDepartment = param?.row?.user?.department === user?.department;
      const isHR = user?.department === 'HR';
      const data = {status: value}
      
      if (isAdmin || (isSupervisor && (isSameDepartment || isHR))) {
        setLoader(true)
        MySwal.fire({
          title: 'Are you sure?',
          text: 'Do you want to proceed with this action?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true, // Disable the confirmation button
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Yes, proceed',
          cancelButtonColor: '#d33'
          }).then(async (result) => {
              if (result.isConfirmed) {
                  // User clicked "OK", proceed with the action
                  const res = await updateLeaveRequest(user?.token, data, param?.row?._id);
                  if (res?.success) {
                    MySwal.fire({
                        icon: 'success',
                        title: res?.message,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    const requests = await getAllLeaves(user?.token);
                    dispatch(handleAddUserLeave(requests?.data));
                  } else {
                      MySwal.fire({
                          icon: 'error',
                          title: res?.message,
                          showConfirmButton: false,
                          timer: 3000
                      });
                  }

              } else if (result.dismiss === Swal.DismissReason.cancel) {
                  MySwal.fire({
                      icon: 'error',
                      text: `Your request to ${value} has been canceled`,
                      showConfirmButton: false,
                      timer: 2000
                  });
              }
          });
      
      }
      setLoader(false)
    }catch(error){
      console.log(error)
      setLoader(false)
      MySwal.fire({
        icon: 'error',
        title: "There's an error processing your request, Try again",
        showConfirmButton: false,
        timer: 3000
      });
    }
  }

  return (
  <div className='action__cell-popover'>
      <div className="action__cell-buttons">
        {
          loader ? <LinearProgress sx={{width: '100%'}}/>
          : <>
            <button className={`action__cell-button approve ${
              param?.row?.adminApproval === 'Approved' ? 'request_active' : ''
            }`}
            disabled={param?.row?.adminApproval === 'Approved' ? true : false}
            onClick={()=>handleUpdateRequest('Approved')}>
              Approve
            </button>
            <button className={`action__cell-button reject ${
              param?.row?.adminApproval === 'Rejected' ? 'request_active' : ''
            }`}
            disabled={param?.row?.adminApproval === 'Rejected' ? true : false}
            onClick={()=>handleUpdateRequest('Rejected')}>
              Reject
            </button>
          </>
        }
      </div>
  </div>
  )
}

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderCell: (param)=>{
        return (
            <div className='request__status-profile'>
                <img src={!param?.row?.user?.profilePic ? profile 
                : `https://backend.aspomportal.com/upload/${param?.row?.user?.profilePic}`} 
                alt={param?.row?.user?.firstName + ' ' + param?.row?.user?.lastName}
                className="request__status-img" />
                <div className="request__status-profile_list">
                    <h4 className="request__status-profile_name">
                        {param?.row?.user?.firstName + ' ' + param?.row?.user?.lastName}
                    </h4>
                    <span className="request__status-profile_email">
                        {param?.row?.user?.email}
                    </span>
                </div>
            </div>
        )
    }
  },
  {
    field: 'department',
    headerName: 'Department',
    width: 120,
    renderCell: (param) => (
      <span style={{color: 'var(--white-gray-color)'}}>{param?.row?.user?.department}</span>
    )
  },
  {
    field: 'leaveType',
    headerName: 'Leave Type',
    width: 120,
  },
  {
    field: 'days',
    headerName: 'Durations',
    width: 100,
  },
  {
    field: 'reason',
    headerName: 'Reasons',
    width: 250,
    renderCell: (param) => <ExpandableCell value={param.row.reason} />
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 140,
    renderCell: (param) => (
    <span style={{color: 'var(--white-gray-color)'}}>
      {moment(param.row.startDate).format('MMMM DD YYYY')}
    </span>
    )
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 140,
    renderCell: (param) => (
      <span style={{color: 'var(--white-gray-color)'}}>
        {moment(param.row.endDate).format('MMMM DD YYYY')}
      </span>
      )
  },
  {
    field: 'createdAt',
    headerName: 'Applied On',
    width: 140,
    renderCell: (param) => (
      <span style={{color: 'var(--white-gray-color)'}}>
        {moment(param.row.createdAt).format('MMMM DD YYYY')}
      </span>
      )
  },
  {
    field: 'uhApproval',
    headerName: 'Unit Head',
    width: 120,
    renderCell: (param) => (
      <span className={`action__cell-span
        ${param.row.uhApproval.toLowerCase()}
      `}>
        {param.row.uhApproval === 'Approved' ?
        <i class='bx bxs-like action__cell-span__icon approve'></i>
        : param?.row?.uhApproval === 'Pending' ?
        <i class='bx bx-error-circle action__cell-span__icon pending'></i>
        : param?.row?.uhApproval === 'Rejected' ?
        <i class='bx bxs-dislike action__cell-span__icon reject'></i>
        : ''}
        
        {param.row.uhApproval}
      </span>
    )
  },
  {
    field: 'hrApproval',
    headerName: 'HR',
    width: 120,
    renderCell: (param) => (
      <span className={`action__cell-span 
        ${param.row.hrApproval.toLowerCase()}
      `}>
        {param.row.hrApproval === 'Approved' ?
        <i class='bx bxs-like action__cell-span__icon approve'></i>
        : param?.row?.hrApproval === 'Pending' ?
        <i class='bx bx-error-circle action__cell-span__icon pending'></i>
        : param?.row?.hrApproval === 'Rejected' ?
        <i class='bx bxs-dislike action__cell-span__icon reject'></i>
        : ''}
        {param.row.hrApproval}
      </span>
    )
  },
  {
    field: 'adminApproval',
    headerName: 'Administrator',
    width: 120,
    renderCell: (param) => (
      <span className={`action__cell-span
        ${param.row.adminApproval.toLowerCase()}
      `}>
        {param.row.adminApproval === 'Approved' ?
        <i class='bx bxs-like action__cell-span__icon approve'></i>
        : param?.row?.adminApproval === 'Pending' ?
        <i class='bx bx-error-circle action__cell-span__icon pending'></i>
        : param?.row?.adminApproval === 'Rejected' ?
        <i class='bx bxs-dislike action__cell-span__icon reject'></i>
        : ''}
        {param.row.adminApproval}
      </span>
    )
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 220,
    renderCell: (param) => <ActionCell param={param}/>
  },
];


const UserRequestStatus = () => {
    const {user} = useSelector((state)=> state.user)
    const {userLeaves} = useSelector((state)=> state.leave)
    const dispatch = useDispatch()
    const [value, setValue] = useState('')

  const fetchLeaveRequest = async ()=>{
    try{
        const res = await getAllLeaves(user?.token)
        dispatch(handleAddUserLeave(res?.data))
    }catch(error){
        console.log(error)
    }
  }

  useEffect(()=>{
      fetchLeaveRequest()
  },[])

  const filterItems = userLeaves?.filter((item) => {
    // Check if the user is Supervisor and the item uhApproval is Approved
    if (user.role === 'Supervisor' && user?.department === item?.user?.department) {
      return true; // Show for HR
    }
    if (user.department === 'HR' && item.uhApproval === 'Approved') {
      return true; // Show for HR
    }
    // Check if the user is HR and the item hrApproval is Approved
    if (user.role === 'Administrator' && item.uhApproval === 'Approved' && item.hrApproval === 'Approved') {
      return true; // Show for Administrator
    }

    return false;
  });


  return (
    <section className='request__status'>
        {/* HEADING */}
        <div className="heading">
            <div className="heading__box">
                <div className="heading-item">
                    <h3 className="heading-title">Leave Requests | </h3>
                    <i class='bx bx-home heading__title-icon'></i>
                    <Link to="/" className="heading-text">
                        Home <i class='bx bx-chevron-right heading-icon'></i>
                    </Link>
                    <span className="heading-text">
                        Employees Leave Requests<i class='bx bx-chevron-right heading-icon'></i>
                    </span>
                </div>
            </div>
        </div>
        <div className="request__status-container">

            <div className="request__status-content">
              {/* HEADING */}
              <div className="request__status-heading">
                  <div className="request__status-search">
                    <span>Search:</span>
                    <input type="text" placeholder='Search....'
                    value={value} onChange={(e)=>setValue(e.target.value)}
                    className='request__status-input'/>
                  </div>
              </div>

              {/* DATA TABLE */}
              <div className="request__status-table">
                    <DataGrid
                    getRowId={(rows => rows._id)}
                    rows={filterItems ?? []}
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                        },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    getRowHeight={() => 'auto'} 
                    getEstimatedRowHeight={() => 120}
                    sx={{
                        maxWidth: 1000,
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: 0.5,
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: '10px',
                        },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                        py: '15px',
                        },
                    }}
                    experimentalFeatures={{ lazyLoading: true }}
                    />
              </div>
            </div>
        </div>
    </section>
  )
}

export default UserRequestStatus