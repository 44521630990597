import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    sidebar: true,
    notification: false,
    notifyMessage: false,
}

const settingssSlice = createSlice({
    name: 'settings',
    initialState,
    reducers:{
        setSideBar(state, action){
            state.sidebar = action.payload
        },
        showNotification(state, action){
            state.notification = action.payload
        },
        showMessage(state, action){
            state.notifyMessage = action.payload
        },
    }
})


export const toggleSideBar = (val)=>{
    return dispach => {
        dispach(settingssSlice.actions.setSideBar(val))
    }
}

export const toggleNotification = (val)=>{
    return dispach => {
        dispach(settingssSlice.actions.showNotification(val))
    }
}

export const toggleMessage = (val)=>{
    return dispach => {
        dispach(settingssSlice.actions.showMessage(val))
    }
}



export default settingssSlice.reducer