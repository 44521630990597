import React, {useState, useEffect} from 'react'
import './admins.css'
import { DataGrid } from '@mui/x-data-grid';
// import ActionCell from '../../actionCell/ActionCell'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { addAdmin, getAdmin } from '../../api'
import {handleAdmins} from '../../state/userSlice'
import LinearProgress from '@mui/material/LinearProgress';
import { useForm } from 'react-hook-form'
import Loader from '../loader/Loader'

// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);

// const ActionCell = ({param}) =>{
//     const [anchorEl, setAnchorEl] = useState(null);
//     const {user} = useSelector((state)=>state.user)
//     const dispatch = useDispatch();
//     const [isLoading, setIsLoading] = useState(false)

//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleClose = () => {
//         setAnchorEl(null);
//     };

//     const handleFetchLocations = async ()=>{
//         try{
//             const res = await getLocations(user?.token);
//             dispatch(handleAddLocation(res?.data))
//         }catch(error){
//             console.log(error)
//         }
//     }

//     const handleDelete = async (id) =>{
//         setIsLoading(true)
//         try {
//             const res = await deleteLocation(user?.token, id)
//             if(res?.success){
//                 MySwal.fire({
//                     icon: 'success',
//                     title: res?.message,
//                     showConfirmButton: false,
//                     timer: 4000
//                 });
//                 handleFetchLocations()
//             }else{
//                 MySwal.fire({
//                     icon: 'error',
//                     title: res?.message,
//                     showConfirmButton: false,
//                     timer: 5000
//                 });
//             }
//             setIsLoading(false)
//         } catch (error) {
//             setIsLoading(false)
//             console.log(error)
//             MySwal.fire({
//                 icon: 'error',
//                 title: "There's an error processing your request, Try again",
//                 showConfirmButton: false,
//                 timer: 5000
//             });
//         }
//     }

//     const open = Boolean(anchorEl);
//     const id = open ? `popover-${param?.row?.id || param?.row?._id}` : undefined;

//     return(
//         <div className="location__table-status">
//             <i class='bx bx-dots-vertical-rounded action__cell-icon'
//             onClick={handleClick}></i>
//             <Popover
//             id={id}
//             open={open}
//             anchorEl={anchorEl}
//             onClose={handleClose}
//             anchorOrigin={{
//                 vertical: 'bottom',
//                 horizontal: 'left',
//             }}
//             sx={{
//                 '& .MuiPopover-paper': {p: 1,
//                     background: 'var(--bg-color) !important',
//                     boxShadow: '0 0 10px 0px rgba(0, 0, 0, 0.1)',
//                     minWidth: 100,
//                     display: 'flex',
//                     flexDirection: 'column',
//                     alignItems: 'start',
//                     gap: '0.5rem',
//                 },
//             }}
//             >   {
//                     isLoading ? <LinearProgress sx={{width: '100%'}}/>
//                     : <button onClick={() =>handleDelete(param?.row?._id)}
//                     className="location__table-popover">
//                         Delete
//                     </button>
//                 }
                
//             </Popover>
//         </div>  
//     )
// }


const columns = [
    { field: 'id', headerName: 'ID', width: 80 },
    {
        field: 'name',
        headerName: 'Name',
        width: 300,
        renderCell: (params) => (
            <span>{params?.row?.firstName + ' ' + params?.row?.lastName}</span>
        )
    },
    {
        field: 'department',
        headerName: 'Department',
        width: 150,
    },
    {
        field: 'role',
        headerName: 'Role',
        width: 150,
    },
    // {
    //     field: 'action',
    //     headerName: 'Action',
    //     width: 120,
    //     editable: true,
    //     renderCell: (param) => <ActionCell param={param} />
    // },
];


const AddAdmins = ({setToggleModal, user, dispatch, fetchAdmins}) => {
    
    const [isLoading, setIsLoading] = useState(false)
    const {
        register,
        handleSubmit,
        formState:{errors},
        getValues,
        reset,
    } = useForm({
        mode: 'onChange'
    })

    const onSubmit = async (data) =>{
        setIsLoading(true)
        try{
            const res = await addAdmin(user?.token, data)
            setToggleModal(false)
            if(res?.success){
                MySwal.fire({
                    icon: 'success',
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 3000
                });
                reset()
                fetchAdmins()
            }else{
                MySwal.fire({
                    icon: 'error',
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 4000
                });
            }
                setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
            MySwal.fire({
                icon: 'error',
                title: "There's an error processing your request, Try again",
                showConfirmButton: false,
                timer: 3000
            });
        }
    }

    return (
        <div className='modal' 
        onClick={()=>setToggleModal(false)}>
            <div className="modal-container">
                <div className="modal-content overflow" 
                onClick={(e)=>e.stopPropagation()}>
                    {/* TODO FORM */}
                    <form className="modal-form" 
                    onSubmit={handleSubmit(onSubmit)}
                    >
                        {/* FORM HEADING */}
                        <div className="modal-heading">
                            {/* TITLE */}
                            <h4 className="modal-title">
                                Add Administrator
                            </h4>
                            {/* CLOSE BUTTON */}
                            <button onClick={()=>setToggleModal(false)}
                            className="modal-btn">
                                <i class='bx bx-x'></i>
                            </button>
                        </div>

                        <div className="modal-items">
                            {/* FIRSTNAME */}
                            <div className="modal-field">
                                <label htmlFor="title" className="modal-label">
                                    FirstName
                                </label>
                                <input name="firstName" type="text" className="modal-input"
                                    {...register('firstName',{required: 'This field is required'})}
                                />
                                {errors.firstName && <span className="modal-error">
                                    {errors.firstName.message}
                                </span>}
                            </div>

                            {/* LASTNAME */}
                            <div className="modal-field">
                                <label htmlFor="title" className="modal-label">
                                    LastName
                                </label>
                                <input name="lastName" type="text" className="modal-input"
                                    {...register('lastName',{required: 'This field is required'})}
                                />
                                {errors.lastName && <span className="modal-error">
                                    {errors.lastName.message}
                                </span>}
                            </div>

                            {/* STAFF ID */}
                            <div className="modal-field">
                                <label htmlFor="title" className="modal-label">
                                    Staff ID
                                </label>
                                <input name="staffId" type="text" className="modal-input"
                                    {...register('staffId',{required: 'This field is required'})}
                                />
                                {errors.staffId && <span className="modal-error">
                                    {errors.staffId.message}
                                </span>}
                            </div>

                            {/* EMAIL */}
                            <div className="modal-field">
                                <label htmlFor="title" className="modal-label">
                                    Email (example@aspomtravels.com)
                                </label>
                                <input name="email" type="text" className="modal-input"
                                    {...register('email',{required: 'This field is required'})}
                                />
                                {errors.email && <span className="modal-error">
                                    {errors.email.message}
                                </span>}
                            </div>

                            {/* DEPARTMENT */}
                            <div className="modal-field">
                                <label htmlFor="title" className="modal-label">
                                    Department
                                </label>
                                <select className="modal-input"
                                    {...register('department',{required: 'This field is required'})}>
                                    <option value="MD">Managing Director</option>
                                    <option value="MP">Managing Partner</option>
                                </select>
                                {errors.department && <span className="modal-error">
                                    {errors.department.message}
                                </span>}
                            </div>

                            {/* ROLE */}
                            <div className="modal-field">
                                <label htmlFor="title" className="modal-label">
                                    Role
                                </label>
                                <input name="role" type="text" className="modal-input" readOnly
                                value="Administrator"
                                    {...register('role',{required: 'This field is required'})}
                                />
                                {errors.role && <span className="modal-error">
                                    {errors.role.message}
                                </span>}
                            </div>

                            {/* PASSWORD */}
                            <div className="modal-field">
                                <label htmlFor="title" className="modal-label">
                                    Password
                                </label>
                                <input name="password" type="password" className="modal-input"
                                    {...register('password',{required: 'This field is required'})}
                                />
                                {errors.password && <span className="modal-error">
                                    {errors.password.message}
                                </span>}
                            </div>

                            {/* CONFIRM PASSWORD */}
                            <div className="modal-field">
                                <label htmlFor="title" className="modal-label">
                                    Confirm Password
                                </label>
                                <input name="cpassword" type="text" className="modal-input"
                                    {...register('cpassword',{
                                        validate: (value)=>{
                                            const {password} = getValues()
                                            if(value !== password){
                                                return 'Password not match'
                                            }
                                        }
                                    })}
                                />
                                {errors.cpassword && <span className="modal-error">
                                    {errors.cpassword.message}
                                </span>}
                            </div>

                        </div>

                        {/* SUBMIT BUTTON */}
                        <div className="modal-field">
                        {
                            isLoading ? <Loader/> 
                            : <button 
                            type='submit'
                            className="modal-button btn">
                                Submit
                            </button>
                        }
                        </div>

                    </form>
                </div>
            </div>
        </div>


    )
}


const Admins = () => {
    const {user, admins} = useSelector((state)=>state.user)
    const [toggleModal, setToggleModal] = useState(false)
    const [value, setValue] = useState('');
    const dispatch = useDispatch()

    const filterItem = admins?.filter((item)=>{
        return item?.firstName?.toLowerCase()?.includes(value.toLowerCase()) 
        || item?.lastName?.toLowerCase()?.includes(value.toLowerCase()) 
        || item?.staffId?.toLowerCase()?.includes(value.toLowerCase()) || []
    })

    const fetchAdmins = async () => {
        try{
            const res = await getAdmin(user?.token)
            dispatch(handleAdmins(res?.data))
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        fetchAdmins()
    },[])

    return (
        <>
            <div className="location__table">
                <div className="location__table-heading">
                    <div className="location__table-search">
                        <span className="location__search-span">Search:</span>
                        <input type='text' name='search' id='search'
                        placeholder="Search..." value={value}
                        onChange={(e)=>setValue(e.target.value)}
                        className="location__search-input"/>
                    </div>
                    <button onClick={()=>setToggleModal(!toggleModal)}
                    className="btn"
                    >Add</button>
                </div>
                <div className="location__table-data">
                    <DataGrid
                        getRowId={(rows => rows.id)}
                        rows={filterItem?.map((item, i)=>({
                            id: i + 1,
                            ...item
                        })) ?? []}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        getRowHeight={() => 'auto'} 
                        sx={{                            
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                            py: 0.5,
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                            py: '5px',
                        },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '10px',
                        },
                        }}
                        experimentalFeatures={{ lazyLoading: true }}
                    />
                </div>
            </div>
            {toggleModal && <AddAdmins 
            setToggleModal={setToggleModal} 
            user={user}
            dispatch={dispatch}
            fetchAdmins={fetchAdmins}/>}
        </>
    )
}


export default Admins