import React, {useState} from 'react'
import './changePassword.css'
import { useForm } from 'react-hook-form'
import { changePassword } from '../../api'
import Loader from '../loader/Loader';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { useDispatch, useSelector } from 'react-redux';

// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);


const ChangePassword = () => {
    const [isLoading, setIsLoading] = useState(false)
    const {user} = useSelector((state)=>state.user)
    

    const {
        register,
        handleSubmit,
        formState:{errors},
        reset,
        getValues
    } = useForm({
    mode: 'onChange',
    })

    // Submit handler for the form
    const handleChangePass = async (data) => {
        setIsLoading(true)
        try{
            const res = await changePassword(user?.token, data)
            if(res?.success){
                reset()
                MySwal.fire({
                    icon: 'success',
                    title: "Successfull",
                    text: res?.message,
                    showConfirmButton: false,
                    timer: 4000
                });
            }else{
                MySwal.fire({
                    icon: 'error',
                    title: "Oops...",
                    text: res?.message,
                    showConfirmButton: false,
                    timer: 4000
                });
            }
            setIsLoading(false)
        }catch(error){
            console.log(error)
            setIsLoading(false)
            MySwal.fire({
                icon: 'error',
                title: "Error Occurred!",
                text: "There's an error processing your request, Try again",
                showConfirmButton: false,
                timer: 4000
            });
        }
    }

  return (
    <form className='profile__form' onSubmit={handleSubmit(handleChangePass)}>

        <div className="profile__form-list">
            {/* FIRSTNAME */}
            <div className="profile__form-item">
                <label htmlFor="firstName" className="profile__form-label">
                    Name
                </label>
                <input type="text" name='firstName' 
                className="profile__form-input" readOnly
                value={user?.firstName + ' ' + user?.lastName}/>
            </div>
            {/* OLD PASSWORD */}
            <div className="profile__form-item">
                <label htmlFor="oldPassword" className="profile__form-label">
                    Old Password
                </label>
                <input type="password" name='oldPassword' 
                className="profile__form-input" 
                {...register('oldPassword',{required: 'This field is required'})}/>
                <span className="profile__form-error">
                    {errors.oldPassword && errors.oldPassword.message}
                </span>
            </div>
            {/* NEW PASSWORD */}
            <div className="profile__form-item">
                <label htmlFor="newPassword" className="profile__form-label">
                    New Password
                </label>
                <input type="password" name='newPassword' 
                className="profile__form-input" 
                {...register('newPassword',{required: 'This field is required',
                minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters long'
                },
                validate: {
                    containsUppercase: value => /[A-Z]/.test(value) || 'Password must contain at least one uppercase letter',
                    containsLowercase: value => /[a-z]/.test(value) || 'Password must contain at least one lowercase letter',
                    containsNumber: value => /\d/.test(value) || 'Password must contain at least one number'
                }})}/>
                <span className="profile__form-error">
                    {errors.newPassword && errors.newPassword.message}
                </span>
            </div>
            {/* CONFIRM NEW PASSWORD */}
            <div className="profile__form-item">
                <label htmlFor="cNewPassword" className="profile__form-label">
                    Confirm New Password
                </label>
                <input type="password" name='cNewPassword' 
                className="profile__form-input" 
                {...register('cNewPassword',{
                    validate: (value) =>{
                        const {newPassword} = getValues()
                        if(value !== newPassword){
                            return 'Password not match'
                        }
                }})}/>
                <span className="profile__form-error">
                    {errors.cNewPassword && errors.cNewPassword.message}
                </span>
            </div>

        </div>
        
        {/* SUBMIT BUTTON */}
        <div className="profile__form-item">
            {isLoading ? <Loader/> :
            <button type='submit'
            className="profile__form-btn btn">
            Submit
            </button>
            }
        </div>

    </form>
  )
}

export default ChangePassword