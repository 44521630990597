import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
    ArcElement,
    Tooltip,
)


const TotalStaffChart = ({result}) => {
    const data = {
        labels: [
            'Male',
            'Female'
        ],
        datasets: [{
            label: 'Employees',
            data: result,
            backgroundColor: [
                'rgb(54, 162, 235)',
                'rgb(255, 99, 132)',
            ],
            hoverOffset: 4
        }]
    };
    
    const options = {
        responsive: true,
        maintainAspectRatio: false,
    };
  return (
    <div className="total__staff-content_chart">
        <div style={{ width: '100%', height: '150px' }}>
            <Doughnut data={data} options={options} />
        </div>
        <div className="total__staff-content_values">
            <div className="total__staff-content_value">
                <h4 className="total__staff-content_value-name">
                    <span></span>
                    Male
                </h4>
                <span className="total__staff-content_count">
                    {result[0]}
                </span>
            </div>
            <div className="total__staff-content_value">
                <h4 className="total__staff-content_value-name">
                    <span></span>
                    Female
                </h4>
                <span className="total__staff-content_count">
                    {result[1]}
                </span>
            </div>
        </div>
    </div>
  )
}

export default TotalStaffChart