import { apiRequest } from "."


export const getAllAdminReports = async (token)=>{
    try {
        const response = await apiRequest({
            url: `/report/staffs`,
            token: token,
        })

        return response
    } catch (error) {
        console.log(error)
    }
}