import React, {useState, useEffect, useRef, useMemo} from 'react'
import './chatRoom.css'
import { Link, useParams } from 'react-router-dom'
import profile from '../../assets/profile.png'
import {useSelector, useDispatch} from 'react-redux'
import InputEmoji from 'react-input-emoji'
import { getAllAcounts} from '../../api'
import {handleUsers} from '../../state/userSlice'
import {handleAddChat} from '../../state/chatSlice'
import { conversation, fetchConversations, 
    fetchMessages, sendMessages, updateMessages } from '../../api/chatApi'
import moment from 'moment'
import { GrAttachment } from "react-icons/gr";
import { LiaTimesSolid } from "react-icons/lia";
import { IoSend } from "react-icons/io5";



const ChatBox = ({user, chats, socketUsers, socket, handleFetchConversations}) =>{
    const [inputField, setInputField] = useState('')
    const [messages, setMessages] = useState([])
    const [file, setFile] = useState(null)
    const [image, setImage] = useState(null)
    const scroll = useRef()
    
    const handleInputField = (val)=>{
        setInputField(val)
    }
    const handleSelectFile = (e)=>{
        const file = e.target.files[0]
        const fileImg = URL.createObjectURL(file)
        setFile(file)
        setImage(fileImg)
    }

    const handleCancelFile = ()=>{
        setImage(null)
        setFile(null)
    }
    
    const userInfo = chats?.participants?.filter((item)=>{
        return item._id !== user._id
    })?.[0] || {} 
    const userOnline = socketUsers?.find((user)=>user?.user === userInfo?._id)
    
    useEffect(() => {
        scroll.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const handleFetchMessages = async () => {
        try {
            const res = await fetchMessages(user?.token, chats?._id);
            setMessages(res?.data);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    useEffect(() => {
        handleFetchMessages();
    }, [chats]);

    const handleSendMessage = async () => {
        try {
            if (inputField.length === 0) {
                window.alert('Please enter a message');
                return;
            }

            const formData = new FormData();
            formData.append('chatId', chats?._id);
            formData.append('senderId', user?._id);
            formData.append('text', inputField);
            formData.append('upload', file);

            const receiver = chats?.participants?.find((id) => id?._id?.toString() !== user?._id?.toString());
            const socketMes = {
                _id: chats?._id,
                senderId: user?._id,
                senderName: user?.firstName + ' ' + user?.lastName,
                picture: user?.profilePic,
                text: inputField,
                status: 'Unread',
            };

            socket.emit('send-message', { ...socketMes, receiverId: receiver?._id });
            setMessages((prev) => [socketMes, ...prev]);

            await sendMessages(user?.token, formData);

            setInputField('');
            handleCancelFile();
            handleFetchMessages();
            handleFetchConversations();
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    useEffect(() => {
        socket.on('receive-message', () => {
            handleFetchMessages();
            handleFetchConversations();
        });
    }, []);

    return (
        <div className="chatbox">
            <div className="chatbox__heading">
                <div className="chatbox__profile">
                    <img src={profile} alt="" className="chatbox__profile-img"/>
                    <div className="chatbox__profile-user">
                        <h4 className="chatbox__profile-name">
                            {userInfo?.firstName + ' ' + userInfo?.lastName}
                        </h4>
                        <h4 className={`chatbox__profile-status ${
                            userOnline ? 'online' : 'offline'
                        }`}>{userOnline ? 'Online' : 'Offline'}</h4>
                    </div>
                </div>
                <div className="chatbox__menu">
                    <i class='bx bx-dots-vertical-rounded'></i>
                </div>
            </div>
            <div className="chatbox__content">
                <div className="chatbox__content-item">
                    {
                        messages?.map((item, i)=> (
                                <div key={i} ref={scroll}
                                className={`chatbox__content-chats ${
                                    item?.senderId === user?._id ? 'right' : 'left'
                                }`}>
                                    <span className="chatbox__content-date">
                                        {moment(item?.createdAt).format('HH:mm:ss A')}
                                    </span>
                                    {
                                        item?.attachment && (
                                            <img src={`https://backend.aspomportal.com/upload/${item?.attachment}`} 
                                            alt={item?.attachment}
                                            className='chatbox__content-img'/>
                                        )
                                    }
                                    <div className="chatbox__content-message">
                                        <p>{item?.text}</p>
                                        <span className="arrow-down"></span>
                                    </div>
                                </div> 
                            ) 
                        )
                    }
                </div>
                {/* TEXT BOX FIELD */}
                <div className="chatbox__content-field">
                    {
                        image && (
                            <div className="chatbox__file-output">
                                <button className="chatbox__file-btn"
                                onClick={handleCancelFile}>
                                    <LiaTimesSolid size={20}/>
                                </button>
                                <img src={image} 
                                className="chatbox__file-output_img" />
                            </div>
                        )
                    }
                    <div className="chatbox__content-list">
                        <input type="file" name="upload" id="upload"
                        className='chatbox__file' onChange={handleSelectFile}/>
                        <label htmlFor="upload" className="chatbox__label">
                            <GrAttachment size={18}/>
                        </label>
                        <div className="chatbox__input">
                            <InputEmoji
                            value={inputField}
                            onChange={handleInputField}
                            />
                        </div>
                        <button onClick={handleSendMessage}
                        className=" btn">
                            <IoSend size={20} color='var(--primary-color)'/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ChatRoom = ({socket}) => {
    const {user, users} = useSelector((state) => state.user);
    const {chat} = useSelector((state) => state.chat);
    const [socketUsers, setSocketUsers] = useState()
    const [activeChat, setActiveChat] = useState()
    const [value, setValue] = useState('')
    const dispatch = useDispatch() 
    const {queryId} = useParams()

    const handleFetchConversations = async () => {
        try{
            const res = await fetchConversations(user?.token)
            dispatch(handleAddChat(res?.data))
        }catch(error){
            console.log(error)
        }
    }

    const handleUpdateMessage = async (id) => {
        try{
            const res = await updateMessages(user?.token, id)
            if(res?.success)handleFetchConversations()
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        socket.emit('new-user', user?._id)
        socket.on('get-users', (socketUsers)=>{
            // console.log('This are the connected users', socketUsers)
            setSocketUsers(socketUsers)
        })
    },[])

    useEffect(()=>{
        handleUpdateMessage(activeChat?._id)
    },[activeChat])

    const fetchData = async ()=> {
        try {
            const [userData, conversationData] = await Promise.all([
                getAllAcounts(user?.token),
                fetchConversations(user?.token)
            ]);
            dispatch(handleUsers(userData?.data));
            dispatch(handleAddChat(conversationData?.data));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
        return () => {
            socket.disconnect(); // Disconnect socket when component unmounts
        };
    }, [dispatch, user, socket]);

    const handleConversation = async (id) => {
        try {
            const data = { receiverId: id };
            const res = await conversation(user?.token, data);
            setActiveChat(res?.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (queryId) {
            handleConversation(queryId);
        }
    }, [queryId, user]);

    const combinedArray = [];

    chat?.forEach(conv => {
        conv?.participants.forEach(participant => {
            if (participant?._id !== user?._id && !combinedArray?.some(item => item?._id === participant?._id)) {
                combinedArray?.push(participant);
            }
        });
    });

    users?.forEach(u => {
        if (!chat?.some(conv => conv?.participants?.some(participant => participant?._id === u?._id)) &&
            !combinedArray?.some(item => item?._id === u?._id)) {
            combinedArray.push(u);
        }
    });

    const filterItems = combinedArray?.filter((item)=>{
        return item?.firstName?.toLowerCase()?.includes(value.toLowerCase())
        || item?.lastName?.toLowerCase()?.includes(value.toLowerCase())
    })


    return (
        <section className='chat'>

            {/* HEADING */}
            <div className="heading">
                <div className="heading__box">
                    <div className="heading-item">
                        <h3 className="heading-title">Chat | </h3>
                        <i class='bx bx-home heading__title-icon'></i>
                        <Link to="/" className="heading-text">
                            Home <i class='bx bx-chevron-right heading-icon'></i>
                        </Link>
                        <span className="heading-text">
                            Chat <i class='bx bx-chevron-right heading-icon'></i>
                        </span>
                    </div>
                    <p className="heading-text">
                        Chat room
                    </p>
                </div>
            </div>

            {/* CHAT CONTAINER */}

            <div className="chat__container">

                <div className="chat__container-left">
                    <div className="chat__container-search">
                        <i class='bx bx-search'></i>
                        <input type="text" name="" 
                        placeholder='Search...'
                        value={value}
                        onChange={(e)=>setValue(e.target.value)}
                        className="chat__search-input"/>
                    </div>
                        
                    <div className="chat__container-left_content">
                        {filterItems?.map((conversation, i) => {
                            const socketUser = socketUsers?.find(socketUser => socketUser?.user === conversation?._id);
                            const unreadCount = chat?.filter(mess => {
                                const participant = mess?.participants?.find(p => p?._id === user?._id)
                                const unreadMessages = mess?.messages?.find(m => m.senderId === conversation?._id && m?.status === 'Unread');
                                if(participant){
                                    return unreadMessages
                                }
                            })?.length ?? 0;
                            return (
                                <div className="chat__container-left_profile" key={i}
                                    onClick={() => handleConversation(conversation?._id)}>
                                    <img src={profile} alt="" 
                                    className="chat__container-left_img" />
                                    <div className="chat__container-left_user">
                                        <h4 className="chat__container-left_name">
                                            {conversation?.firstName + ' ' + conversation?.lastName}
                                        </h4>
                                        <span className={`chat__container-left_status ${socketUser?.status === 'online' ? 'online' : 'offline'}`}>
                                            {socketUser?.status === 'online' ? 'online' : 'offline'}
                                        </span>
                                    </div>
                                    {unreadCount > 0 && <span className='chat__container-left_count'>{unreadCount}</span>}
                                    
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="chat__container-right">
                    {
                        activeChat ? (
                            <ChatBox user={user} chats={activeChat} 
                            socket={socket}
                            socketUsers={socketUsers} 
                            handleGetUsers={fetchData}
                            handleFetchConversations={handleFetchConversations}/>
                        ) : (
                            <div className="chat__container-right_heading">
                                <h4 className="chat__container-right_title">
                                    Click on a profile to start a coversation
                                </h4>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    )
}

export default ChatRoom