import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    location: [],
}

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        addLocation: (state, action)=>{
            state.location = action.payload
        },
    }
})

export const handleAddLocation = (state)=>{
    return dispatch => {
        dispatch(locationSlice.actions.addLocation(state))
    }
}


export default locationSlice.reducer