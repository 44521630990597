import React from 'react'
import './notificationBox.css'
import profile from '../../assets/profile.png'
import { useDispatch, useSelector } from 'react-redux'
import { toggleNotification } from '../../state/settingsSlice'
import { handleClearNotifications } from '../../state/userSlice'
import { Link } from 'react-router-dom'


const NotificationBox = () =>{
    const {user, notifications} = useSelector((state)=>state.user)
    const {notification} = useSelector((state)=>state.settings)
    const dispatch = useDispatch()

    const handleNotification = () => {
        dispatch(toggleNotification(!notification))
    }

    const handleClearAll = () => {
        dispatch(handleClearNotifications())
    }

    return(
        <div className={`notification ${notification && 'active'}`}>
            <div className={`notification__overlay`} onClick={handleNotification}></div>
            
            <div className="notification__box">
                <div className="notification__top">
                    <h3 className="notification__title">Notifications</h3>
                    <button onClick={handleClearAll}
                    className="notification__btn">
                        Clear all
                    </button>
                </div>

                <div className="notification__list">
                    {
                        notifications?.slice(0, 5)?.map((item, i)=>(
                            item?.user !== user?._id &&
                            <div 
                            className="notification__items" key={i}>
                                <div className="notication__data">
                                    <img src={profile} className="notification__img"/>
                                    <div>
                                        <p className="notification__text">
                                            <b>{item?.userName}</b> {' '}
                                            {item?.like 
                                            ? (item?.postOwnerId === user?._id ? 'Like your post' 
                                            : item?.postOwnerId === item?.user ? 'Like his post' 
                                            : `Like ${item?.postOwnerName} post`)
                                            : item?.comment 
                                            ? (item?.postOwnerId === user?._id ? 'Comment on your post' 
                                            : item?.postOwnerId === item?.user ? 'Comment on his/her post'
                                            : `Comment on ${item?.postOwnerName} post`)
                                            : item?.message
                                        }
                                        </p>
                                        <span className="notification__date">
                                            {item?.date}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="notification__bottom">
                    {
                        notifications?.length > 5 &&
                        <Link to="/activities" className="notification__bottom-link">
                            View all notifications
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
}

export default NotificationBox;