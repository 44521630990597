import {apiRequest} from '.'

export const addDepartment = async (token, data) =>{
    try{
        const response = await apiRequest({
            url: '/department',
            method: 'POST',
            data: data,
            token
        })

        return response
    }catch(error){
        console.log(error)
    }
}

export const updateDepartment = async (token, data, deptId) =>{
    try{
        const response = await apiRequest({
            url: `/department/${deptId}`,
            method: 'PUT',
            data: data,
            token
        })

        return response
    }catch(error){
        console.log(error)
    }
}

export const fetchDepartment = async (token) =>{
    try{
        const response = await apiRequest({
            url: '/department',
            token
        })

        return response
    }catch(error){
        console.log(error)
    }
}