import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chat: [],
    message: []
}

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        addChat: (state, action)=>{
            state.chat = action.payload
        },
        getMessage:(state,action) =>{
            const newMessage = action.payload;
            const existingIndex = state.message.findIndex(message => message._id === newMessage._id);
            if (existingIndex === -1) {
                state.message.push(newMessage); 
            } else {
                state.message[existingIndex] = newMessage;
            }
        },
        clearMessage: (state)=>{
            state.message = []
        }
    }
})

export const handleAddChat = (state)=>{
    return dispatch => {
        dispatch(chatSlice.actions.addChat(state))
    }
}

export const handleAddMessages = (state)=>{
    return dispatch => {
        dispatch(chatSlice.actions.getMessage(state))
    }
}

export const handleClearMess = ()=>{
    return dispatch => {
        dispatch(chatSlice.actions.clearMessage())
    }
}


export default chatSlice.reducer