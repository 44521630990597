import React, { useState, useRef } from 'react';
import './settings.css'
import { Link } from 'react-router-dom';
import LocationForm from '../../components/location/LocationForm'
import ChangePassword from '../../components/changePassword/ChangePassword'
import Admins from '../../components/admins/Admins'
import { useDispatch, useSelector } from 'react-redux';



const Settings = () => {
    const [settingsTab, setSettingsTab] = useState('location')
    const {user} = useSelector((state)=>state.user)
    const dispatch = useDispatch()
  
    return (
        <section className="admin__settings">
            {/* HEADING */}
            <div className="heading">
                <div className="heading__box">
                <div className="heading-item">
                    <h3 className="heading-title">Settings | </h3>
                    <i class='bx bx-home heading__title-icon'></i>
                    <Link to="/" className="heading-text">
                    Home <i class='bx bx-chevron-right heading-icon'></i>
                    </Link>
                    <span className="heading-text">
                    Settings <i class='bx bx-chevron-right heading-icon'></i>
                    </span>
                </div>
                    <p className="heading-text">
                        Configure settings 
                    </p>
                </div>
            </div>

            <div className="admin__settings__container">
                <div className="admin__settings__content">
                    <div className="admin__settings__tabs">
                        <button onClick={(e)=>{setSettingsTab("location")}}
                        className={`admin__settings__tab ${
                            settingsTab === "location" ? "active" : ""
                        }`}>
                            Localization
                        </button>
                        <button onClick={(e)=>{setSettingsTab("administrator")}}
                        className={`admin__settings__tab ${
                            settingsTab === "administrator" ? "active" : ""
                        }`}>
                            Administrators
                        </button>
                        <button onClick={(e)=>{setSettingsTab("company")}}
                        className={`admin__settings__tab ${
                            settingsTab === "company" ? "active" : ""
                        }`}>
                            Company Settings
                        </button>
                        <button onClick={(e)=>{setSettingsTab("roles")}}
                        className={`admin__settings__tab ${
                            settingsTab === "roles" ? "active" : ""
                        }`}>
                            Roles & Permission
                        </button>
                        <button onClick={(e)=>{setSettingsTab("approval")}}
                        className={`admin__settings__tab ${
                            settingsTab === "approval" ? "active" : ""
                        }`}>
                            Approval Settings
                        </button>
                        <button onClick={(e)=>{setSettingsTab("leave")}}
                        className={`admin__settings__tab ${
                            settingsTab === "leave" ? "active" : ""
                        }`}>
                            Leave type
                        </button>
                        <button onClick={(e)=>{setSettingsTab("password")}}
                        className={`admin__settings__tab ${
                            settingsTab === "password" ? "active" : ""
                        }`}>
                            Change Password
                        </button>
                    </div>
                    <div className="admin__settings__tab-items">
                        {settingsTab === 'location' && <LocationForm user={user}
                        dispatch={dispatch}/>}
                        {settingsTab === 'administrator' && <Admins user={user}
                        dispatch={dispatch}/>}
                        {settingsTab === 'password' && <ChangePassword/>}
                    </div>
                </div>
            </div>
        </section>
    );
    }
export default Settings;