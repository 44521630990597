import React, { useEffect, useState } from 'react'
import './sidebar.css'
import logo from '../../assets/Aspom-Logo.png'
import profile from '../../assets/profile.png'
import { Link, useLocation } from 'react-router-dom'

import { MdOutlineDashboard, MdAccountBalance } from "react-icons/md";
import { FaRegNewspaper, FaPlus, FaMinus, FaPersonCircleQuestion } from "react-icons/fa6";
import { PiUsersFour, PiHandsClappingDuotone } from "react-icons/pi";
import { AiOutlineTrademark } from "react-icons/ai";
import { IoChatbubblesOutline } from "react-icons/io5";
import { GiPayMoney } from "react-icons/gi";
import { IoMdSettings } from "react-icons/io";
import { TbLogout } from "react-icons/tb";
import { SiGooglemeet } from "react-icons/si";

import { useDispatch, useSelector } from 'react-redux'
import { toggleSideBar } from '../../state/settingsSlice'
import { userLogout } from '../../state/userSlice'

const Sidebar = () => {
    const [subSidebar, setSubSidebar] = useState('')
    const {user} = useSelector((state)=>state.user)
    const {sidebar} = useSelector((state)=>state.settings)
    const dispatch = useDispatch()
    const location = useLocation()

    const handleClose = () => {
        dispatch(toggleSideBar(!sidebar))
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 992 && sidebar) {
                dispatch(toggleSideBar(false))
            }else if(window.innerWidth >= 992 && !sidebar){
                dispatch(toggleSideBar(true))
            }
        };
    
        // Listen for window resize events
        window.addEventListener('resize', handleResize);
    
        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [sidebar]); 

    const handleLogout = () =>{
        dispatch(userLogout())
    }

    const handleToggleSubSidebar = (val)=>{
        if(subSidebar === val){
            setSubSidebar('')
        }else{
            setSubSidebar(val)
        }
    }

  return (
    <aside className={`sidebar ${!sidebar ? 'active' : ''}`}
    onClick={handleClose}>
        <div className="sidebar__container"
        onClick={(e)=>e.stopPropagation()}>
            <div className="sidebar_div">
                <img src={logo} alt="" className="sidebar__logo" />
                <div className="sidebar__content">
                    <div className="sidebar__profile">
                        <img src={profile} alt="" className="sidebar__profile-img" />
                        <div className="sidebar__profile-items">
                            <h3 className="sidebar__profile-name">
                                {user?.firstName + ' ' + user?.lastName}
                            </h3>
                            <span className="sidebar__profile-role">
                                {user?.department} - {user?.role}
                            </span>
                        </div>
                    </div>

                    <div className="sidebar__items">
                        <h3 className="sidebar__items-title">Main</h3>
                        <div className="sidebar__list">
                            <Link to={'/'} className={`sidebar__link ${
                                location.pathname === '/' ? 'active' : ''
                            }`}>
                                <MdOutlineDashboard className='sidebar__icon'/>
                                Dashboard
                            </Link>

                            <Link to={'/news-stand'} className={`sidebar__link ${
                                location.pathname.includes('/news-stand') ? 'active' : ''
                            }`}>
                                <FaRegNewspaper className='sidebar__icon'/>
                                News Stand
                            </Link>

                            <div className='sidebar__list-sub'>
                                <button onClick={()=>handleToggleSubSidebar('accounts')}
                                className="sidebar__link button">
                                    <span>
                                        <MdAccountBalance className='sidebar__icon'/>
                                        Accounts
                                    </span>
                                    {
                                        subSidebar === 'accounts' ? <FaMinus className='sidebar__icon'/>
                                        : <FaPlus className='sidebar__icon'/>
                                    }
                                </button>
                                <ul className={`sidebar__list-ul ${
                                    subSidebar === 'accounts' ? 'active' : ''
                                }`}>
                                    <li>
                                        <Link to={'/payment-approval'} className={`sidebar__link ${
                                            location.pathname.includes('/payment-approval') ? 'active' : ''
                                        }`}>
                                            <GiPayMoney className='sidebar__icon'/>
                                            Payment Approval
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className='sidebar__list-sub'>
                                <button onClick={()=>handleToggleSubSidebar('employee')}
                                className="sidebar__link button">
                                    <span>
                                        <PiUsersFour className='sidebar__icon'/>
                                        Employees
                                    </span>
                                    {
                                        subSidebar === 'employee' ? <FaMinus className='sidebar__icon'/>
                                        : <FaPlus className='sidebar__icon'/>
                                    }
                                </button>
                                <ul className={`sidebar__list-ul ${
                                    subSidebar === 'employee' ? 'active' : ''
                                }`}>
                                    <li>
                                        <Link to={'/employees'} className={`sidebar__link ${
                                            location.pathname.includes('/employees') ? 'active' : ''
                                        }`}>
                                            <PiUsersFour className='sidebar__icon'/>
                                            All Employees
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/appraisals'} className={`sidebar__link ${
                                            location.pathname.includes('/appraisals') ? 'active' : ''
                                        }`}>
                                            <PiHandsClappingDuotone className='sidebar__icon'/>
                                            Appraisals
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/leave-requests'} className={`sidebar__link ${
                                            location.pathname.includes('/leave-requests') ? 'active' : ''
                                        }`}>
                                            <AiOutlineTrademark className='sidebar__icon'/>
                                            Leave Requests
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            
                            <Link to={'/chat'} className={`sidebar__link ${
                                location.pathname.includes('/chat') ? 'active' : ''
                            }`}>
                                <IoChatbubblesOutline className='sidebar__icon'/>
                                Chat Room
                            </Link>

                            <Link to={'/meeting'} className={`sidebar__link ${
                                location.pathname.includes('/meeting') ? 'active' : ''
                            }`}>
                                <SiGooglemeet className='sidebar__icon'/>
                                Meeting
                            </Link>

                            <Link to={'/query-&-response'} className={`sidebar__link ${
                                location.pathname.includes('/query-&-response') ? 'active' : ''
                            }`}>
                                <FaPersonCircleQuestion className='sidebar__icon'/>
                                Query & Response
                            </Link>
                        </div>

                        <h3 className="sidebar__items-title">General</h3>
                        <div className="sidebar__list">
                            <Link to={'/settings'} className={`sidebar__link ${
                                location.pathname.includes('/settings') ? 'active' : ''
                            }`}>
                                <IoMdSettings className='sidebar__icon'/>
                                Settings
                            </Link>
                            <Link onClick={handleLogout}
                            className="sidebar__link">
                                <TbLogout className='sidebar__icon'/>
                                Logout
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aside>
  )
}

export default Sidebar