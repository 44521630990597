import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    task: [],
    allTasks: []
}

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        addTask: (state, action)=>{
            state.task = action.payload
        },
        addAllTask: (state, action)=>{
            state.allTasks = action.payload
        },
    }
})

export const handleAddTask = (state)=>{
    return dispatch => {
        dispatch(taskSlice.actions.addTask(state))
    }
}

export const handleAddAllTask = (state)=>{
    return dispatch => {
        dispatch(taskSlice.actions.addAllTask(state))
    }
}

export default taskSlice.reducer