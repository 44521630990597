import React from 'react'
import './payment.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {useNavigate, Link} from 'react-router-dom'

import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineQuestionMark  } from "react-icons/md";
import { HiMiniXMark } from "react-icons/hi2";


const expense = [
    {
        vendor: 'Charger',
        product: 'USB Charger',
        description: `Lorem ipsum dolor sit amet consectetur adipisicing elit.`,
        amount: '2,000',
        accountName: 'Kazeem Oluwafemi',
        accountNumber: '0468727885',
        status: 'Pending',
    },
    {
        vendor: 'Charger',
        product: 'USB Charger',
        description: `Lorem ipsum dolor sit amet consectetur adipisicing elit.`,
        amount: '2,000',
        accountName: 'Kazeem Oluwafemi',
        accountNumber: '0468727885',
        status: 'Declined',
    },
    {
        vendor: 'Charger',
        product: 'USB Charger',
        description: `Lorem ipsum dolor sit amet consectetur adipisicing elit.`,
        amount: '2,000',
        accountName: 'Kazeem Oluwafemi',
        accountNumber: '0468727885',
        status: 'Approved',
    },
]

const PaymentTable = ({staffs, user}) => {
    const navigate = useNavigate()
  return (
    <div className='payment__table'>
        <div className="payment__table-header">
            <h3 className="payment__table-title">Payment Approval (KINDLY Note: THIS IS A DUMMY DATA)</h3>
            <Link className='payment__table-link'>
                View all
            </Link>
        </div>
        <TableContainer sx={{ maxWidth: 1000}} 
        className="payment__table-container">
            <Table sx={{ minWidth: 350,with: '100%'}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{
                            color: 'var(--white-gray-color) !important',
                            minWidth: 150,
                            }}>
                                VENDOR
                        </TableCell>
                        <TableCell sx={{
                            color: 'var(--white-gray-color) !important',
                            minWidth: 150,
                            }} align="left">
                            PRODUCT
                        </TableCell>
                        <TableCell sx={{
                            color: 'var(--white-gray-color) !important',
                            minWidth: 250,
                            }} align="left">
                            DESCRIPTION
                        </TableCell>
                        <TableCell sx={{
                            color: 'var(--white-gray-color) !important',
                            minWidth: 80,}} align="left">
                            AMOUNT
                        </TableCell>
                        <TableCell sx={{
                            color: 'var(--white-gray-color) !important',
                            minWidth: 80,}} align="left">
                            STATUS
                        </TableCell>
                        <TableCell sx={{
                            color: 'var(--white-gray-color) !important',
                            minWidth: 150,}} align="left">
                            ACCOUNT NAME
                        </TableCell>
                        <TableCell sx={{
                            color: 'var(--white-gray-color) !important',
                            minWidth: 160,}} align="left">
                            ACCOUNT NUMBER
                        </TableCell>
                        <TableCell sx={{
                            color: 'var(--white-gray-color) !important',
                            minWidth: 150,}} align="center">
                            ACTION
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {expense?.slice(0, 5)?.map((item, i) => (
                    <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 },  }}
                    >
                        <TableCell sx={{color: 'var(--white-gray-color) !important'}} 
                        component="th" scope="row" >
                            {item?.vendor}
                        </TableCell>
                        <TableCell sx={{color: 'var(--white-gray-color) !important'}} 
                        align="left">
                            {item?.product}
                        </TableCell>
                        <TableCell sx={{color: 'var(--white-gray-color) !important'}} 
                        align="left">
                            {item?.description}
                        </TableCell>
                        <TableCell sx={{color: 'var(--white-gray-color) !important'}} 
                        align="left">
                            {item?.amount}
                        </TableCell>
                        <TableCell sx={{color: 'var(--white-gray-color) !important'}} 
                        align="left">
                            <span className={`payment__table-status ${
                                item?.status?.toLowerCase()
                            }`}>
                                {item?.status === 'Approved' ? <IoMdCheckmark className="payment__table-status_icon"/>
                                : item?.status === 'Pending' ? <MdOutlineQuestionMark className="payment__table-status_icon"/>
                                : item?.status === 'Declined' ? <HiMiniXMark className="payment__table-status_icon"/> : ''}
                                
                                {item?.status}
                            </span>
                        </TableCell>
                        <TableCell sx={{color: 'var(--white-gray-color) !important'}} 
                        align="left">
                            {item?.accountName}
                        </TableCell>
                        <TableCell sx={{color: 'var(--white-gray-color) !important'}} 
                        align="left">
                            {item?.accountNumber}
                        </TableCell>

                        <TableCell align="center">
                            <div className="payment__table-div">
                                <button
                                className="payment__table-btn approve">
                                    Approve
                                </button>
                                <button
                                className="payment__table-btn decline">
                                    Decline
                                </button>
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
  )
}

export default PaymentTable