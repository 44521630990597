import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    department: []
}

const deptSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        addDept: (state, action)=>{
            state.department = action.payload
        }
    }
})

export const handleDepartment= (state)=>{
    return dispatch => {
        dispatch(deptSlice.actions.addDept(state))
    }
}

export default deptSlice.reducer