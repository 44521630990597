import React, { useState, useEffect } from 'react'
import './employee.css'
import { Link } from 'react-router-dom'
import { FaUsers,FaUser,FaUserAlt } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import profile from '../../assets/profile.png'
import { useSelector, useDispatch} from 'react-redux'
import {getAllUsers, getAllAcounts, addEmployee} from '../../api'
import {handleStaffs} from '../../state/userSlice'
import {handleOpenModal, handleCloseModal} from '../../state/modalSlice'
import moment from 'moment';
import Loader from '../../components/loader/Loader'
// import ActionCell from '../../../components/actionCell/ActionCell'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);


const columns = [
    {
        field: 'name',
        headerName: 'Name',
        width: 250,
        renderCell: (param)=>{
            return (
                <div className='employees__table-profile'>
                    <img src={!param.row.profilePic ? profile
                    : `https://hrms.aspomportal.com/upload/${param.row.profilePic}`} 
                    alt={param.row.firstName + ' ' + param.row.lastName}
                    className="employees__table-img" />
                    <div className="employees__table-profile_list">
                        <h4 className="employees__table-profile_name">
                            {param.row.firstName + ' ' + param.row.lastName}
                        </h4>
                        <span className="employees__table-profile_email">
                            {param.row.email}
                        </span>
                    </div>
                </div>
            )
        }
    },
    {
        field: 'staffId',
        headerName: 'Employee ID',
        width: 150,
        editable: true,
    },
    {
        field: 'department',
        headerName: 'Department',
        width: 150,
    },
    {
        field: 'role',
        headerName: 'Role',
        width: 150,
    },
    {
        field: 'createdAt',
        headerName: 'Join Date',
        width: 120,
        renderCell: (param) => (
            <span>
                {moment(param.row.createdAt).format('DD MMMM YYYY')}
            </span>
        )
    },
];


const Employee = () => {
    const {user, staffs} = useSelector((state)=>state.user)
    const dispatch = useDispatch()
    const [value, setValue] = useState('')


    const handleFetchUsers = async () => {
        try{
            const res = await getAllAcounts(user?.token)
            dispatch(handleStaffs(res?.data))
        }catch(error){
            console.log(error)
        }
    }

    const filterItems = staffs?.filter((item)=>{
        return item?.firstName.toLowerCase().includes(value.toLowerCase())
        || item?.lastName.toLowerCase().includes(value.toLowerCase())
        || item?.email.toLowerCase().includes(value.toLowerCase())
    })

    const today = new Date()
    const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    
    const staffsTwoMonthsAgo = staffs?.filter((item) => {
    const createdAtDate = new Date(item?.createdAt);
        return createdAtDate >= twoMonthsAgo && createdAtDate < today;
    });

    const staffsTwoMonthsAgoLength = staffsTwoMonthsAgo?.length;

    useEffect(()=>{
        handleFetchUsers()
    },[])

  return (
    <section className='employees'>
        {/* HEADING */}
        <div className="heading">
            <div className="heading__box">
                <div className="heading-item">
                    <h3 className="heading-title">All Employees | </h3>
                    <i class='bx bx-home heading__title-icon'></i>
                    <Link to="/" className="heading-text">
                        Home <i class='bx bx-chevron-right heading-icon'></i>
                    </Link>
                    <span className="heading-text">
                        Employees <i class='bx bx-chevron-right heading-icon'></i>
                    </span>
                </div>
                <p className="heading-text">
                    Employee list
                </p>
            </div>
        </div>

        <div className="employees__container">
            <div className="employees__cards">
                <div className="employees__card">
                    <div className="employees__card-item">
                        <h4 className="employees__card-title">
                            Total Employees
                        </h4>
                        <span className="employees__card-count">
                            {staffs?.length}
                        </span>
                    </div>
                    <div className="employees__card-icon">
                        <FaUsers size={50} color="green" />
                    </div>
                </div>
                <div className="employees__card">
                    <div className="employees__card-item">
                        <h4 className="employees__card-title">
                            New Employees
                        </h4>
                        <span className="employees__card-count">
                            {staffsTwoMonthsAgoLength}
                        </span>
                    </div>
                    <div className="employees__card-icon">
                        <HiUsers size={50} color="var(--primary-color)" />
                    </div>
                </div>
                <div className="employees__card">
                    <div className="employees__card-item">
                        <h4 className="employees__card-title">
                            Male
                        </h4>
                        <span className="employees__card-count">
                            {staffs?.filter((item)=>item?.gender?.toLowerCase() === 'male')?.length}
                        </span>
                    </div>
                    <div className="employees__card-icon">
                        <FaUser size={40} color="#1E90FF" />
                    </div>
                </div>
                <div className="employees__card">
                    <div className="employees__card-item">
                        <h4 className="employees__card-title">
                            Female
                        </h4>
                        <span className="employees__card-count">
                            {staffs?.filter((item)=>item?.gender?.toLowerCase() === 'female')?.length}
                        </span>
                    </div>
                    <div className="employees__card-icon">
                        <FaUserAlt size={40} color="var(--orange-color)" />
                    </div>
                </div>
            </div>
            <div className="employees__items">
                <div className="employees__search">
                    <h4 className="employees__search-title">Employee List</h4>
                    <div className="employees__search-box">
                        <span className="employees__search-span">Search:</span>
                        <input type="text" placeholder="Name or email..."
                        value={value} onChange={(e)=>setValue(e.target.value)}
                        className='employees__search-input'/>
                    </div>
                </div>

                <div className="employees__table">
                    <DataGrid
                        getRowId={(rows => rows._id)}
                        rows={filterItems ?? []}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        getRowHeight={() => 'auto'} 
                        sx={{
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                                py: 0.5,
                            },
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                                py: '5px',
                            },
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                                py: '10px',
                            },
                        }}
                        experimentalFeatures={{ lazyLoading: true }}
                    />
                </div>
            </div>
        </div>
        
    </section>
  )
}

export default Employee