import React from 'react'
import './loader.css'

const Loader = () => {
  return (
      <div className="loader__box">
        <div className="loader"></div>
        <div className="loader"></div>
        <div className="loader"></div>
      </div>
  )
}

export default Loader