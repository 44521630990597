import React, {useEffect} from 'react'
import './absentCard.css'
import profile from '../../assets/profile.png'
import { useDispatch, useSelector } from 'react-redux'
import { handleUsers } from '../../state/userSlice'
import { getAllAcounts, } from '../../api'
import moment from 'moment'

const AbsentCard = ()=>{
    const {user, users} = useSelector((state)=> state.user)
  const dispatch = useDispatch()

  const handleFetchUsers = async () => {
    try{
        const res = await getAllAcounts(user?.token)
        dispatch(handleUsers(res?.data))
    }catch(error){
        console.log(error)
    }
  }

  useEffect(()=>{
    handleFetchUsers()
  },[])

  const today = new Date().toISOString().split('T')[0]

  const userNotClockIn = users?.filter((item)=>{
    return !item?.attendance?.some((entry)=>{
      return entry?.days?.some((day)=>day?.clockIn && day?.clockIn?.startsWith(today))
    })
  })

    return(
        <div className="absent__card">
            <div className="absent__card-header">
                <h3 className="absent__card-title">
                    Absent Today
                </h3>
                <span className="absent__card-count">
                    {userNotClockIn?.length}
                </span>
            </div>
            <div className="absent__card-content">
                {
                    userNotClockIn?.slice(0,4)?.map((item, i)=>(
                        <div className="absent__card-items" key={i}>
                            <img src={profile} className="absent__card-img"/>
                            <div className="absent__card-div">
                                <h3 className="absent__card-name">
                                    {item?.firstName + ' ' + item?.lastName}
                                </h3>
                                <span className="absent__card-role">
                                    {item?.role}
                                </span>
                            </div>
                            <span className="absent__card-date">
                                {moment().format('DD MMMM YYYY')}
                            </span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default AbsentCard