import React, { useEffect, useState } from 'react'
import './home.css'

import { HiOutlineUserGroup } from "react-icons/hi2";
import { RiBusWifiLine } from "react-icons/ri";
import { TbBusStop } from "react-icons/tb";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";

import PerformanceChart from '../../components/charts/Performance';
import TotalStaffs from '../../components/totalStaffs/TotalStaffs';
import AbsentCard from '../../components/absentCard/AbsentCard';
import Activities from '../../components/activities/Activities';
import PaymentTable from '../../components/tables/payment/Payment';

import { useDispatch, useSelector } from 'react-redux'
import { handleStaffs } from '../../state/userSlice'
import { getAllUsers, } from '../../api'
import { getAllLeaves, staffsOnLeave } from '../../api/leaveApi'
import { handleAddUserLeave } from '../../state/leaveSlice'

const Card = ({icon, title, value}) =>{
  return(
    <div className='home__card'>
      <span className="home__card-span">
        {icon}
      </span>
      <div className="home__card-item">
        <h3 className="home__card-title">{title}</h3>
        <p className="home__card-value">{value}</p>
      </div>
    </div>
  )
}

const Home = () => {
  const {user, staffs} = useSelector((state)=> state.user)
  const {userLeaves} = useSelector((state)=> state.leave)
  const [activeLeave, setActiveLeave] = useState([])
  const dispatch = useDispatch()

  const handleFetchUsers = async () => {
    try{
        const res = await getAllUsers(user?.token)
        dispatch(handleStaffs(res?.data))
    }catch(error){
        console.log(error)
    }
  }

  const fetchLeaveRequest = async ()=>{
    try{
        const res = await getAllLeaves(user?.token)
        dispatch(handleAddUserLeave(res?.data))
    }catch(error){
        console.log(error)
    }
  }

  const fetchStaffsOnLeave = async ()=>{
    try{
        const res = await staffsOnLeave(user?.token)
        setActiveLeave(res?.data)
    }catch(error){
        console.log(error)
    }
  }

  useEffect(()=>{
    handleFetchUsers()
    fetchLeaveRequest()
    fetchStaffsOnLeave()
  },[])

  useEffect(() => {
    // Check if Notification API is supported by the browser
    if ('Notification' in window) {
      // Check notification permission
      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
      }
    }
  }, []);

  const filterLeave = userLeaves?.filter((item)=>{
    return item?.adminApproval !== 'Approved'
  })


  return (
    <section className='section'>
      <div className="home__container">
        <div className="home__cards">
          <Card title={'Total Employee'}
          icon={<HiOutlineUserGroup className='home__card-icon'/>} 
          value={staffs?.length}/>
          <Card title={'Leave Requests'}
          icon={<RiBusWifiLine className='home__card-icon'/>} 
          value={filterLeave?.length}/>
          <Card title={'Staffs on Leave'}
          icon={<TbBusStop className='home__card-icon'/>} 
          value={activeLeave?.length}/>
          <Card title={'Payment Approval'}
          icon={<HiOutlineQuestionMarkCircle className='home__card-icon'/>} 
          value={0}/>
        </div>

        <div className="home__charts">
          <PerformanceChart/>
        </div>

        <div className="home__items-grid">
          <TotalStaffs/>
          <AbsentCard/>
          <Activities/>
        </div>

        <div className="home__charts">
          <PaymentTable/>
        </div>
      </div>
    </section>
  )
}

export default Home