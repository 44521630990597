import React, {useState, useEffect} from "react";
import { Routes, Route, useLocation, Outlet, Navigate} from 'react-router-dom'
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import Home from "./pages/home/Home";
import Employee from "./pages/employees/Employee";
import NewsStand from "./pages/newsStand/NewsStand";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/Forgot";
import ResetPassword from "./pages/auth/Reset";
import { useSelector, useDispatch } from "react-redux";
import AppraisalTable from "./pages/appraisal/AppraisalTable";
import UserRequestStatus from "./pages/requestStatus/RequestStatus";
import Settings from "./pages/settings/Settings";
import PaymentApproval from "./pages/paymentApproval/PaymentApproval";
import ChatRoom from "./pages/chatRoom/ChatRoom";
import UpdateAppraisal from "./pages/appraisal/UpdateAppraisal";
import Meeting from "./pages/meeting/Meeting";
import Query from "./pages/query/Query";

import NotificationBox from './components/notificationBox/NotificationBox';
import MessageBox from "./components/messageBox/MessageBox";

// STATE MANAGEMENT
import {handleAddMessages} from './state/chatSlice'
import {handleAddNotifications} from './state/userSlice'

import {io} from 'socket.io-client'

// const socket = io.connect('http://localhost:5000')
// const socket = io.connect('https://backend.aspomportal.com')
const socket = io.connect('https://aspom-server.onrender.com')

const Layout = ({message}) => {
  const {user} = useSelector((state)=>state.user)
  const location = useLocation()
  const {notification, 
    notifyMessage, sidebar} 
    = useSelector((state)=>state.settings)

  return user?.token ? <div className={`layout ${!sidebar ? 'active' : ''}`}>
    <Sidebar/>
    <div className={`layout__container ${!sidebar ? 'active' : ''}`}>
      {notification && <NotificationBox/>}
      {notifyMessage && <MessageBox/>}
      <Topbar message={message}/>
      <Outlet/>
    </div>
  </div> : <Navigate to={'/login'} state={{from: location}} replace/>
}


function App() {
  const {user} = useSelector((state)=>state.user)
  const {message} = useSelector((state)=>state.chat)
  const dispatch = useDispatch()
  const [audio] = useState(new Audio('/notify_tone.mp3'));

  // CONNECT TO SOCKET
  useEffect(()=>{
    socket.emit('new-user', user?._id)
    socket.on('get-users', (socketUsers)=>{
        // console.log('This are the connected users', socketUsers)
    })
  },[])

  // NOTIFICATION SOUND
  const playAudio = () =>{
    audio.play().catch(error => {
      console.error('Failed to play audio:', error);
    });
  }

  // RECEIVE MESSAGE FROM SOCKET
  useEffect(()=>{
    socket.on('receive-message', (data)=>{
      dispatch(handleAddMessages(data))
      playAudio()
      showNotification('New Message', 'You have received a new message.');
    })

    // Listen for notifications event
    socket.on('receive-notification', (data) => {
      dispatch(handleAddNotifications(data));
      playAudio()
      showNotification('New Notification', 'You have received a new notification.');
    });

    // return () => {
    //   socket.off('receive-message');
    //   socket.off('receive-notification');
    // };

  },[])

  // Function to display notification
  const showNotification = (title, body) => {
    if (Notification.permission === 'granted') {
      new Notification(title, { body });
    }
  };

  return (
      <Routes>
        <Route path="/login" element={<Login/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="/reset-password" element={<ResetPassword/>}/>
        <Route element={<Layout message={message} socket={socket}/>}>
          <Route path="/" element={<Home />}/>
          <Route path="/news-stand" element={<NewsStand socket={socket}/>}/>
          <Route path="/employees" element={<Employee />}/>
          <Route path="/appraisals" element={<AppraisalTable />}/>
          <Route path="/appraisal/:id" element={<UpdateAppraisal />}/>
          <Route path="/leave-requests" element={<UserRequestStatus />}/>
          <Route path="/leave-requests" element={<UserRequestStatus />}/>
          <Route path="/payment-approval" element={<PaymentApproval />}/>
          <Route path="/settings" element={<Settings />}/>
          <Route path="/chat" element={<ChatRoom socket={socket}/>}/>
          <Route path="/meeting" element={<Meeting />}/>
          <Route path="/query-&-response" element={<Query />}/>
        </Route>
      </Routes>
  );
}

export default App;
