import React, { useState } from 'react'
import './auth.css'
import logo from '../../assets/Aspom-Logo.png'
import { RiShieldKeyholeLine } from "react-icons/ri";
import { BiUser } from "react-icons/bi";
import { CiLock } from "react-icons/ci";
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { apiRequest } from '../../api';
import { userLogin } from '../../state/userSlice';
import { useDispatch } from 'react-redux';
import Loader from '../../components/loader/Loader';
import { useNavigate } from 'react-router-dom'

// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);


const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState:{errors},
    } = useForm()

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    // LOGIN FUNCTION
  const handleLoginForm = async (data) => {
    setIsLoading(true)
    try {
        const response = await apiRequest({
            url: 'auth/login',
            method: 'POST',
            data: data
        })

        if(response?.success){
            MySwal.fire({
                icon: 'success',
                title: response?.message,
                showConfirmButton: false,
                timer: 2000
            });
            const newData = {token: response?.token, ...response?.user}
            dispatch(userLogin(newData))
            setTimeout(() => {
                navigate('/')
            }, 2000);
        }else{
            MySwal.fire({
                icon: 'error',
                text: response?.message,
                showConfirmButton: false,
                timer: 5000
            });
        }
        setIsLoading(false)
    } catch (error) {
        console.log(error)
        setIsLoading(false)
        MySwal.fire({
            icon: 'error',
            text: "There's an error processing your request, Try again",
            showConfirmButton: false,
            timer: 3000
        });
    }
}

  return (
    <div className='auth'>
        <div className="auth__container">
            <img src={logo} alt="" className="auth__logo" />
            <div className="auth__content">
                <RiShieldKeyholeLine className='auth__icon'/>
                <h3 className="auth__title">
                    Administrator Panel
                </h3>
                <form className="auth__form" onSubmit={handleSubmit(handleLoginForm)}>
                    <div className="auth__list">
                        <div className="auth__items">
                            <label htmlFor="staffId" className="auth__label">
                                StaffId
                            </label>
                            <span className="auth__form-span">
                                <BiUser className='auth__items-icon'/>
                            </span>
                            <input type="text" className="auth__input" name='staffId'
                            {...register('staffId',{required: 'This field is required'})}/>
                            {
                                errors.staffId && <span className="auth__error">
                                    {errors.staffId.message}
                                </span>
                            }
                        </div>
                        <div className="auth__items">
                            <label htmlFor="staffId" className="auth__label">
                                Password
                            </label>
                            <span className="auth__form-span">
                                <CiLock className='auth__items-icon'/>
                            </span>
                            <input type={showPassword ? 'text' : 'password'} 
                            className="auth__input" name='password'
                            {...register('password',{required: 'This field is required'})}/>
                            {
                                errors.staffId && <span className="auth__error">
                                    {errors.password.message}
                                </span>
                            }
                            <i
                                className={`bx ${
                                showPassword ? 'bx-low-vision' : 'bx-show'
                                } auth__form-toggle`}
                                onClick={togglePasswordVisibility}
                            ></i>
                        </div>
                        <div className="auth__items">
                            {
                                isLoading ? <Loader/> :
                                <button type='submit' 
                                className="auth__btn btn">Login</button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Login