import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    leave: [],
    userLeaves: [],
    balance: []
}

const leaveSlice = createSlice({
    name: 'leave',
    initialState,
    reducers: {
        addLeave: (state, action)=>{
            state.leave = action.payload
        },
        addUserLeave: (state, action)=>{
            state.userLeaves = action.payload
        },
        leaveBalance: (state, action)=>{
            state.balance = action.payload
        },
    }
})

export const handleAddLeave= (state)=>{
    return dispatch => {
        dispatch(leaveSlice.actions.addLeave(state))
    }
}

export const handleAddUserLeave= (state)=>{
    return dispatch => {
        dispatch(leaveSlice.actions.addUserLeave(state))
    }
}

export const handleBalance= (state)=>{
    return dispatch => {
        dispatch(leaveSlice.actions.leaveBalance(state))
    }
}

export default leaveSlice.reducer