import React, {useState} from 'react'
import './locationTable.css'
import { DataGrid } from '@mui/x-data-grid';
// import ActionCell from '../actionCell/ActionCell'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { getLocations, updateLocation, deleteLocation } from '../../api/locationApi'
import {handleAddLocation} from '../../state/locationSlice'
import LinearProgress from '@mui/material/LinearProgress';

// Create a SweetAlert2 instance with React integration
const MySwal = withReactContent(Swal);

const ActionCell = ({param}) =>{
    const [anchorEl, setAnchorEl] = useState(null);
    const {user} = useSelector((state)=>state.user)
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFetchLocations = async ()=>{
        try{
            const res = await getLocations(user?.token);
            dispatch(handleAddLocation(res?.data))
        }catch(error){
            console.log(error)
        }
    }

    const handleDelete = async (id) =>{
        setIsLoading(true)
        try {
            const res = await deleteLocation(user?.token, id)
            if(res?.success){
                MySwal.fire({
                    icon: 'success',
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 4000
                });
                handleFetchLocations()
            }else{
                MySwal.fire({
                    icon: 'error',
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 5000
                });
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            MySwal.fire({
                icon: 'error',
                title: "There's an error processing your request, Try again",
                showConfirmButton: false,
                timer: 5000
            });
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? `popover-${param?.row?.id || param?.row?._id}` : undefined;

    return(
        <div className="location__table-status">
            <i class='bx bx-dots-vertical-rounded action__cell-icon'
            onClick={handleClick}></i>
            <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPopover-paper': {p: 1,
                    background: 'var(--bg-color) !important',
                    boxShadow: '0 0 10px 0px rgba(0, 0, 0, 0.1)',
                    minWidth: 100,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: '0.5rem',
                },
            }}
            >   {
                    isLoading ? <LinearProgress sx={{width: '100%'}}/>
                    : <button onClick={() =>handleDelete(param?.row?._id)}
                    className="location__table-popover">
                        Delete
                    </button>
                }
                
            </Popover>
        </div>  
    )
}

const StatusCell = ({param}) =>{
    const [anchorEl, setAnchorEl] = useState(null);
    const {user} = useSelector((state)=>state.user)
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFetchLocations = async ()=>{
        try{
            const res = await getLocations(user?.token);
            dispatch(handleAddLocation(res?.data))
        }catch(error){
            console.log(error)
        }
    }

    const handleUpdate = async (id) =>{
        setIsLoading(true)
        try {
            const res = await updateLocation(user?.token, id)
            if(res?.success){
                MySwal.fire({
                    icon: 'success',
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 4000
                });
                handleFetchLocations()
            }else{
                MySwal.fire({
                    icon: 'error',
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 5000
                });
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            MySwal.fire({
                icon: 'error',
                title: "There's an error processing your request, Try again",
                showConfirmButton: false,
                timer: 5000
            });
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? `popover-${param?.row?.id || param?.row?._id}` : undefined;

    return(
        <div className="location__table-status">
            <button onClick={handleClick}
            className="location__table-status_btn">
                {
                    param?.row?.status === 'Open' ?
                    <i class='bx bx-lock-open-alt location__table-status_icon open'></i>
                    : <i class='bx bx-lock-alt location__table-status_icon close'></i>
                }
                    {param?.row?.status}
                <i class='bx bx-chevron-down location__table-status_icon'></i>
            </button>
            <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPopover-paper': {p: 1,
                    background: 'var(--bg-color) !important',
                    boxShadow: '0 0 10px 0px rgba(0, 0, 0, 0.1)',
                    minWidth: 100,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: '0.5rem',
                },
            }}
            >   
                {
                    isLoading ? <LinearProgress/> : <>
                    <button onClick={()=>handleUpdate(param?.row?._id)}
                    className="location__table-popover"
                    disabled={param?.row?.status === 'Open'}>
                        <i class='bx bx-lock-open-alt location__table-status_icon open'></i>
                        Open
                    </button>
                    <button onClick={()=>handleUpdate(param?.row?._id)}
                    className="location__table-popover"
                    disabled={param?.row?.status === 'Closed'}>
                        <i class='bx bx-lock-alt location__table-status_icon close'></i>
                        Close
                    </button>
                    </>
                }
            </Popover>
        </div>  
    )
}

const columns = [
    { field: 'id', headerName: 'ID', width: 80 },
    {
        field: 'name',
        headerName: 'Name',
        width: 150,
    },
    {
        field: 'street',
        headerName: 'Location',
        width: 300,
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 120,
        renderCell: (param)=>(
            <span>
                {moment(param?.row?.createdAt).format('DD MMMM YYYY')}
            </span>
        )
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 130,
        renderCell: (param) => <StatusCell param={param}/>
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 120,
        editable: true,
        renderCell: (param) => <ActionCell param={param} />
    },
];




const LocationTable = () => {
    const {location} = useSelector((state)=>state.location)
    const [value, setValue] = useState('');

    const filterItem = location?.filter((item)=>{
        return item?.name?.toLowerCase()?.includes(value.toLowerCase()) || []
    })

    return (
        <div className="location__table">
            <div className="location__table-heading">
                <h3 className="location__table-title">Added Locations</h3>
                <div className="location__table-search">
                    <span className="location__search-span">Search:</span>
                    <input type='text' name='search' id='search'
                    placeholder="Search..." value={value}
                    onChange={(e)=>setValue(e.target.value)}
                    className="location__search-input"/>
                </div>
            </div>
            <div className="location__table-data">
                <div className="location__table-datagrid">
                    <DataGrid
                        getRowId={(rows => rows._id)}
                        rows={filterItem?.map((item, i)=>({
                            id: i +1,
                            ...item
                        })) || []}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        getRowHeight={() => 'auto'} 
                        sx={{                            
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                            py: 0.5,
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                            py: '5px',
                        },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '10px',
                        },
                        }}
                        experimentalFeatures={{ lazyLoading: true }}
                    />
                </div>
            </div>
        </div>
    )
}


export default LocationTable