import { apiRequest } from "."


export const conversation = async (token,data)=>{
    try{
        const response = await apiRequest({
            url: '/chat',
            method: 'POST',
            data: data,
            token: token
        })

        return response
    }catch(error){
        console.log(error)
    }
}

export const fetchConversations = async (token)=>{
    try{
        const response = await apiRequest({
            url: `/chat/user`,
            token: token
        })

        return response
    }catch(error){
        console.log(error)
    }
}

export const fetchMessages = async (token,chatId)=>{
    try{
        const response = await apiRequest({
            url: `/chat/messages/${chatId}`,
            token: token
        })

        return response
    }catch(error){
        console.log(error)
    }
}

export const sendMessages = async (token, data)=>{
    try{
        const response = await apiRequest({
            url: `/chat/message`,
            method: 'POST',
            data: data,
            token: token,
            header: 'multipart/form-data',
        })

        return response
    }catch(error){
        console.log(error)
    }
}

export const updateMessages = async (token, id)=>{
    try{
        const response = await apiRequest({
            url: `/chat/messages/${id}`,
            method: 'PUT',
            token: token,
        })

        return response
    }catch(error){
        console.log(error)
    }
}