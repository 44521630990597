import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import taskSlice from "./taskSlice";
import settingsSlice from "./settingsSlice";
import postSlice from "./postSlice";
import reportSlice from "./reportSlice";
import leaveSlice from "./leaveSlice";
import chatSlice from "./chatSlice";
import deptSlice from "./deptSlice";
import appraisalSlice from "./appraisalSlice";
import locationSlice from "./locationSlice";

const rootReducer = combineReducers({
    user: userSlice,
    task: taskSlice,
    settings: settingsSlice,
    post: postSlice,
    report: reportSlice,
    leave: leaveSlice,
    chat: chatSlice,
    department: deptSlice,
    appraisal: appraisalSlice,
    location: locationSlice,
})

export default rootReducer