import React, {useState, useEffect} from 'react'
import './appraisal.css'
import { Link } from 'react-router-dom'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import profile from '../../assets/profile.png'
import ActionCell from '../../components/actionCell/ActionCell'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { fetchAppraisal } from '../../api/appraisalApi';
import { handleAppraisal } from '../../state/appraisalSlice';



const columns = [
    {
        field: 'name',
        headerName: 'Name',
        width: 250,
        renderCell: (param)=>{
            return (
                <div className='appraisalTable__table-profile'>
                    <img src={!param?.row?.user?.img ? profile
                    : `https://backend.aspomportal.com/upload/${param?.row?.user?.img}`} 
                    alt="Profile Pic" 
                    className="appraisalTable__table-img" />
                    <div className="appraisalTable__table-profile_list">
                        <h4 className="appraisalTable__table-profile_name">
                            {param?.row?.user?.firstName + ' ' + param?.row?.user?.lastName}
                        </h4>
                        <span className="appraisalTable__table-profile_email">
                            {param?.row?.user?.email}
                        </span>
                    </div>
                </div>
            )
        }
    },
    {
        field: 'department',
        headerName: 'Department',
        width: 150,
        renderCell: (param) => (
            <span>
                {param?.row?.user?.department}
            </span>
        )
    },
    {
        field: 'role',
        headerName: 'Role',
        width: 120,
        renderCell: (param) => (
            <span>
                {param?.row?.user?.role}
            </span>
        )
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 140,
        renderCell: (param) => (
            <span>
                {moment(param?.row?.createdAt).format('DD MMMM YYYY')}
            </span>
        )
    },
    {
        field: 'hrComment',
        headerName: 'HR',
        width: 140,
        renderCell: (param) => (
            <span className={`appraisalTable__table-status ${
                param?.row?.hrComment ? 'updated' : 'pending'
            }`}>
                {param?.row?.hrComment ? 'Updated' : 'Pending'}
            </span>
        )
    },
    {
        field: 'mpComment',
        headerName: 'MP',
        width: 140,
        renderCell: (param) => (
            <span className={`appraisalTable__table-status ${
                param?.row?.mpComment ? 'updated' : 'pending'
            }`}>
                {param?.row?.mpComment ? 'Updated' : 'Pending'}
            </span>
        )
    },
    {
        field: 'action',
        headerName: 'Action',
        width: 90,
        renderCell: (param) => (
            <Link className='appraisalTable__table-btn btn' 
            to={`/appraisal/${param?.row?._id}`}
            style={{margin: 'auto'}}>
                View
            </Link>
        )
    },
];


const AppraisalTable = () => {
    const {user} = useSelector((state)=>state.user)
    const {appraisal} = useSelector((state)=>state.appraisal)
    const [value, setValue] = useState('')
    const dispatch = useDispatch()

console.log('appraisal', appraisal)
    const handleFetchAppraisal = async () => {
        try {
            const res = await fetchAppraisal(user?.token)
            dispatch(handleAppraisal(res?.data))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        handleFetchAppraisal()
    },[])

    const filterItems = appraisal?.filter((item)=>{
        return item?.user?.firstName?.toLowerCase().includes(value.toLowerCase())
        || item?.user?.lastName?.toLowerCase().includes(value.toLowerCase())
    })

  return (
    <section className='appraisalTable'>
        {/* HEADING */}
        <div className="heading">
            <div className="heading__box">
                <div className="heading-item">
                    <h3 className="heading-title">Employee Appraisal | </h3>
                    <i class='bx bx-home heading__title-icon'></i>
                    <Link to="/" className="heading-text">
                        Home <i class='bx bx-chevron-right heading-icon'></i>
                    </Link>
                    <span className="heading-text">
                    Employee Appraisal <i class='bx bx-chevron-right heading-icon'></i>
                    </span>
                </div>
                <p className="heading-text">
                    View and Update Employee’s Submitted Appraisal.
                </p>
            </div>
        </div>

        <div className="appraisalTable__container">
            <div className="appraisalTable__search">
                <h4 className="appraisalTable__search-title">
                    Appraisals: {appraisal?.length}
                </h4>
                <div className="appraisalTable__search-box">
                    <span className="appraisalTable__search-span">Search:</span>
                    <input type="text" placeholder="Employee name..."
                    value={value} onChange={(e)=>setValue(e.target.value)}
                    className='appraisalTable__search-input'/>
                </div>
            </div> 

            <div className="appraisalTable__table">
                <DataGrid
                    getRowId={(rows => rows._id)}
                    rows={filterItems ?? []}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    getRowHeight={() => 'auto'} 
                    sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                            py: 0.5,
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                            py: '5px',
                        },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '10px',
                        },
                    }}
                    experimentalFeatures={{ lazyLoading: true }}
                    components={{
                        Toolbar: GridToolbar, // Use GridToolbar as the toolbar component
                    }}
                />
            </div>
        </div>

    </section>
  )
}

export default AppraisalTable